import { gql, useQuery } from '@apollo/client';
// Utils
import { Flag } from '../../utils-ts/types';

export interface FlagQueryData {
  flag: boolean;
}

export interface FlagQueryVariables {
  key: Flag;
}

const FlagQuery = gql`
  query FlagQuery($key: String!) {
    flag(key: $key)
  }
`;

export function useFlagQuery(key: Flag) {
  return useQuery<FlagQueryData, FlagQueryVariables>(FlagQuery, {
    variables: {
      key,
    },
  });
}

export default FlagQuery;
