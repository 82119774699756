import Image from 'next/image';
import Link from 'next/link';
// Modules
import dayjs from 'dayjs';
// Components
// import FacebookIcon from '../icons/FacebookIcon';
// import LinkedInIcon from '../icons/LinkedInIcon';
// import TwitterIcon from '../icons/TwitterIcon';
// FUI
import Typography from '../../fui/v2/Typography';
// Utils
// import { OFFICE_NUMBER_FORMATTED } from '../../utils-ts/constants';
import { href, asPath } from '../../utils-ts/pages';

interface Page {
  href: string;
  isExternal: boolean;
  title: string;
}

const COMPANY_PAGES: Page[] = [
  {
    href: 'https://www.investorhub.com/',
    isExternal: true,
    title: 'InvestorHub',
  },
  // {
  //   href: href.about,
  //   isExternal: false,
  //   title: 'About Us',
  // },
  // {
  //   href: 'https://linkedin.com/careersite/freshequities',
  //   isExternal: true,
  //   title: 'Careers',
  // },
  {
    href: 'https://www.freshequities.com/resource-centre/topic/fresh-blog',
    isExternal: true,
    title: 'Blog',
  },
  {
    href: href.termsAndConditions,
    isExternal: false,
    title: 'Terms and Conditions',
  },
  {
    href: href.privacyPolicy,
    isExternal: false,
    title: 'Privacy Policy',
  },
];

const FEATURE_PAGES: Page[] = [
  {
    href: href.liveCapitalRaises,
    isExternal: false,
    title: 'Live Capital Raises',
  },
  {
    href: href.pastCapitalRaises,
    isExternal: false,
    title: 'Past Capital Raises',
  },
];

const MARKET_PAGES: Page[] = [
  {
    href: href.markets,
    isExternal: false,
    title: 'ASX',
  },
  {
    href: href.asx,
    isExternal: false,
    title: 'ASX Companies',
  },
  {
    href: href.asxSectors,
    isExternal: false,
    title: 'ASX Sectors',
  },
  {
    href: asPath.landingPageIndex('Sector breakdown'),
    isExternal: false,
    title: 'Sector Breakdowns',
  },
  {
    href: href.asxDelisted,
    isExternal: false,
    title: 'Delisted Companies',
  },
];

const SUPPORT_PAGES: Page[] = [
  {
    href: href.faq,
    isExternal: false,
    title: 'FAQs',
  },
  {
    href: href.resourceCentre,
    isExternal: false,
    title: 'Resource Centre',
  },
];

// const USE_CASE_PAGES: Page[] = [
//   {
//     href: asPath.landingPage('Use case', 'for-sophisticated-investors'),
//     isExternal: false,
//     title: 'Sophisticated investors',
//   },
//   {
//     href: asPath.landingPage('Use case', 'for-issuers'),
//     isExternal: false,
//     title: 'Issuers',
//   },
// ];

const Footer: React.ComponentType = () => {
  function renderLink(page: Page) {
    if (page.isExternal) {
      return (
        <a
          className="text-white"
          href={page.href}
          rel="noopener noreferrer"
          target="_blank"
        >
          <Typography>{page.title}</Typography>
        </a>
      );
    }

    return (
      <Link href={page.href}>
        <a className="text-white">
          <Typography>{page.title}</Typography>
        </a>
      </Link>
    );
  }

  return (
    <div className="text-white bg-premium md:snap-end">
      <div className="py-16 px-6 mx-auto max-w-screen-xl">
        <div className="space-y-12">
          <div className="grid grid-cols-12 gap-4">
            <div className="col-span-12 md:col-span-4">
              <div className="space-y-6 md:max-w-[270px]">
                <div>
                  <Image
                    alt="Fresh Equities"
                    height={50}
                    objectFit="contain"
                    src="/images/assets/logo-horizontal-light.svg"
                    width={230}
                  />
                </div>

                {/* Social Icons Desktop */}
                {/* <div className="hidden md:block">
                  <div className="flex space-x-2">
                    <a
                      className="text-white"
                      href="https://www.facebook.com/freshequities"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <FacebookIcon className="w-5 h-5" />
                    </a>
                    <a
                      className="text-white"
                      href="https://www.linkedin.com/company/freshequities/"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <LinkedInIcon className="w-5 h-5" />
                    </a>
                    <a
                      className="text-white"
                      href="https://twitter.com/FreshEquities/"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <TwitterIcon className="w-5 h-5" />
                    </a>
                  </div>
                </div> */}

                <div className="hidden md:block">
                  <div className="flex space-x-4">
                    <a
                      href="https://apps.apple.com/au/app/fresh-equities/id1486674685"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <Image
                        alt="App Store"
                        height={32}
                        objectFit="contain"
                        src="/images/assets/apple-badge.svg"
                        width={96}
                      />
                    </a>
                    <a
                      href="https://play.google.com/store/apps/details?id=com.freshequities.app"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <Image
                        alt="Play Store"
                        height={32}
                        objectFit="contain"
                        src="/images/assets/google-badge.svg"
                        width={108}
                      />
                    </a>
                  </div>
                </div>

                <div className="hidden md:block">
                  <Typography>
                    Copyright &copy; {dayjs().year()} Fresh Equities. All rights
                    reserved. ACN 619 657 028. CAR: 001267539 of AFSL: 471379.
                  </Typography>
                </div>
              </div>
            </div>

            <div className="col-span-6 space-y-4 sm:col-span-3 md:col-span-2">
              <div className="py-2">
                <Typography variant="button">Company</Typography>
              </div>

              {COMPANY_PAGES.map((page) => (
                <div key={page.title}>{renderLink(page)}</div>
              ))}
            </div>

            <div className="col-span-6 space-y-4 sm:col-span-3 md:col-span-2">
              <div className="py-2">
                <Typography variant="button">Support</Typography>
              </div>

              <div>
                <Typography>
                  <span
                    className="outline-none cursor-pointer"
                    onClick={() => window.Intercom && window.Intercom('show')}
                    role="button"
                    tabIndex={0}
                  >
                    Live Chat
                  </span>
                </Typography>
              </div>

              {SUPPORT_PAGES.map((page) => (
                <div key={page.title}>{renderLink(page)}</div>
              ))}

              <div className="py-2">
                <Typography variant="button">Markets</Typography>
              </div>

              {MARKET_PAGES.map((page) => (
                <div key={page.title}>{renderLink(page)}</div>
              ))}
            </div>

            <div className="col-span-6 space-y-4 sm:col-span-3 md:col-span-2">
              <div className="py-2">
                <Typography variant="button">Features</Typography>
              </div>

              {FEATURE_PAGES.map((page) => (
                <div key={page.title}>{renderLink(page)}</div>
              ))}

              {/* <div className="py-2">
                <Typography variant="button">Use Cases</Typography>
              </div>

              {USE_CASE_PAGES.map((page) => (
                <div key={page.title}>{renderLink(page)}</div>
              ))} */}
            </div>

            {/* <div className="col-span-6 space-y-4 sm:col-span-3 md:col-span-2">
              <div className="py-2">
                <Typography variant="button">Contact Us</Typography>
              </div>

              <div>
                <Link href={href.contactUs}>
                  <a className="text-white">
                    <Typography>Contact Form</Typography>
                  </a>
                </Link>
              </div>

              <div>
                <Typography>{OFFICE_NUMBER_FORMATTED}</Typography>
              </div>

              <div>
                <Typography>clients@freshequities.com</Typography>
              </div>

              <div className="py-2">
                <Typography variant="button">Address</Typography>
              </div>

              <div>
                <Typography>
                  Level 16, 452 Flinders St
                  <br />
                  Melbourne VIC 3000
                  <br />
                  Australia
                </Typography>
              </div>
            </div> */}
          </div>
          <div className="space-y-8 md:hidden">
            {/* <div className="flex space-x-2">
              <a
                className="text-white"
                href="https://www.facebook.com/freshequities"
                rel="noopener noreferrer"
                target="_blank"
              >
                <FacebookIcon className="w-5 h-5" />
              </a>
              <a
                className="text-white"
                href="https://www.linkedin.com/company/freshequities/"
                rel="noopener noreferrer"
                target="_blank"
              >
                <LinkedInIcon className="w-5 h-5" />
              </a>
              <a
                className="text-white"
                href="https://twitter.com/FreshEquities/"
                rel="noopener noreferrer"
                target="_blank"
              >
                <TwitterIcon className="w-5 h-5" />
              </a>
            </div> */}

            <div className="flex space-x-4">
              <a
                href="https://apps.apple.com/au/app/fresh-equities/id1486674685"
                rel="noopener noreferrer"
                target="_blank"
              >
                <Image
                  alt="App Store"
                  height={32}
                  objectFit="contain"
                  src="/images/assets/apple-badge.svg"
                  width={96}
                />
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=com.freshequities.app"
                rel="noopener noreferrer"
                target="_blank"
              >
                <Image
                  alt="Play Store"
                  height={32}
                  objectFit="contain"
                  src="/images/assets/google-badge.svg"
                  width={108}
                />
              </a>
            </div>

            <div>
              <Typography>
                Copyright &copy; {dayjs().year()} Fresh Equities. All rights
                reserved. ACN 619 657 028. CAR: 001267539 of AFSL: 471379.
              </Typography>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
