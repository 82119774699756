import React, { FC, useEffect, useState } from 'react';
// Modules
import clsx from 'clsx';
// MUI Core
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { makeStyles, Theme } from '@material-ui/core/styles';
// MUI Icons
import WarningIcon from '@material-ui/icons/Warning';
// Components
import NavBarButton from './NavBarButton';
import NavBarLeft from './NavBarLeft';
import NavBarRight from './NavBarRight';
// FUI
import Typography from '../../../fui/v2/Typography';
// Utils
import { Color } from '../../../utils-ts/theme';
import InvestorHubNavBar from './InvestorHubNavBar';

interface NavBarProps {
  showWallCrossedBanner?: boolean;
  variant?: 'default' | 'home' | 'offer-letter';
}

const useStyles = makeStyles<Theme, { showWallCrossedBanner?: boolean }>(
  (theme) => ({
    hiddenSmDown: {
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    flex1: {
      [theme.breakpoints.up('md')]: {
        flex: 1,
      },
    },
    container: {
      padding: theme.spacing(3, 2),

      [theme.breakpoints.up('md')]: {
        height: 70,
        paddingBottom: 0,
        paddingTop: 0,
      },
    },
    content: {
      alignItems: 'center',
      display: 'flex',
      height: '100%',
    },
    default: {
      backgroundColor: theme.palette.background.default,
      boxShadow: '0px 0px 16px 0 rgba(0,0,0,0)',
      left: 0,
      position: 'fixed',
      right: 0,
      top: 0,
      zIndex: theme.zIndex.appBar,
    },
    home: {
      position: 'absolute',
    },
    link: {
      color: theme.palette.secondary.dark,
    },
    shim: {
      minHeight: 70,

      // 95 is the height of wall crossed sticky banner
      // Header is 96px tall on mobile
      [theme.breakpoints.down('sm')]: {
        minHeight: ({ showWallCrossedBanner }) =>
          showWallCrossedBanner ? 96 + 95 : 96,
      },
    },
    scrolled: {
      boxShadow: '0px 0px 16px 0 rgba(0,0,0,.1)',
    },
    wallCrossedContainer: {
      backgroundColor: Color.CRITICAL_SURFACE,
      borderLeft: `4px solid ${Color.CRITICAL}`,

      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
  }),
);

const NavBar: FC<NavBarProps> = ({
  showWallCrossedBanner,
  variant = 'default',
}) => {
  const classes = useStyles({ showWallCrossedBanner });

  const [isScrolled, setIsScrolled] = useState(false);

  function handleScroll() {
    setIsScrolled(window.pageYOffset > 0);
  }
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  function extraClasses() {
    // Don't add scrolled class on homepage
    if (variant === 'home') {
      return classes.home;
    }
    if (isScrolled) {
      return classes.scrolled;
    }
    return undefined;
  }

  return (
    <>
      <header className={clsx(classes.default, extraClasses())}>
        <InvestorHubNavBar />
        <Container className={classes.container}>
          <div className={classes.content}>
            <Grid container alignItems="center" justify="space-between">
              <NavBarLeft hideMenu={variant === 'offer-letter'} />
              {variant !== 'offer-letter' && <NavBarRight />}
              {variant !== 'offer-letter' && <NavBarButton />}
              {variant === 'offer-letter' && (
                <Grid
                  item
                  className={clsx(classes.hiddenSmDown, classes.flex1)}
                  xs={6}
                  md="auto"
                >
                  <Typography align="right">
                    {'For any enquiries or questions please contact us at '}
                    <a
                      className={classes.link}
                      href="mailto:settlements@freshequities.com"
                    >
                      settlements@freshequities.com
                    </a>
                  </Typography>
                </Grid>
              )}
            </Grid>
          </div>
        </Container>

        {showWallCrossedBanner && (
          <div className={classes.wallCrossedContainer}>
            <Box p={2}>
              <Grid alignItems="center" container spacing={2}>
                <Grid item xs="auto">
                  <WarningIcon color="error" />
                </Grid>

                <Grid item xs>
                  <Typography
                    color="error"
                    style={{ whiteSpace: 'pre-line' }}
                    variant="heading-sm"
                  >
                    You are now wall-crossed.
                    <br />
                    All information relating to this offer
                    <br />
                    is strictly confidential.
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </div>
        )}
      </header>

      {['default', 'offer-letter'].includes(variant) && (
        <div className={classes.shim} />
      )}
    </>
  );
};

export default NavBar;
