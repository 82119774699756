// Modules
import { useMutation, useQuery } from '@apollo/client';
// Graphql
import MarkFeatureOnboardingMutation, {
  MarkFeatureOnboardingMutationData,
  MarkFeatureOnboardingMutationVariables,
} from '../../graphql-ts/mutations/MarkFeatureOnboardingMutation.graphql';
import MeFeatureOnboardingsQuery, {
  MeFeatureOnboardingsQueryData,
  MeFeatureOnboardingsQueryVariables,
} from '../../graphql-ts/queries/MeFeatureOnboardingsQuery.graphql';
// Utils
import { FeatureOnboardingName } from '../../utils-ts/types';

export function useFeatureOnboarding(featureName: FeatureOnboardingName) {
  const [markFeatureOnboarding] = useMutation<
    MarkFeatureOnboardingMutationData,
    MarkFeatureOnboardingMutationVariables
  >(MarkFeatureOnboardingMutation, {
    refetchQueries: ['MeFeatureOnboardingsQuery'],
    variables: { featureName },
  });

  const { data, loading } = useQuery<
    MeFeatureOnboardingsQueryData,
    MeFeatureOnboardingsQueryVariables
  >(MeFeatureOnboardingsQuery, { fetchPolicy: 'cache-first' });

  const showOnboarding = (queryData?: MeFeatureOnboardingsQueryData) => {
    if (queryData) {
      const { meFeatureOnboardings } = queryData;

      // Show the onboarding if record doesn't exist
      return !meFeatureOnboardings.find(
        (mfo) => mfo.featureName === featureName,
      );
    }

    // Error or loading
    return false;
  };

  return {
    isLoading: loading,
    markFeatureOnboarding,
    show: showOnboarding(data),
  };
}
