import React, { FC, useEffect, useState } from 'react';
// Modules
import axios from 'axios';
import Link from 'next/link';
import { useRouter } from 'next/router';
// MUI Core
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
// FUI
import Typography from '../../../../fui/v2/Typography';
// Components
import MegaMenu from './MegaMenu';
import MegaMenuSection from './MegaMenuSection';
// Utils
import { asPath, href } from '../../../../utils-ts/pages';
import { DynamicLandingPage } from '../../../../utils-ts/contentful/landingPages';

const useStyles = makeStyles((theme) => ({
  link: {
    color: theme.palette.secondary.dark,
    fontWeight: 500,
    fontSize: 14,
  },
}));

const Research: FC = () => {
  const classes = useStyles();

  const router = useRouter();

  const [sectors, setSectors] = useState<DynamicLandingPage[]>([]);

  useEffect(() => {
    fetchSectors();
  }, []);

  function fetchSectors() {
    axios
      .get('/api/sectors')
      .then((res) => {
        setSectors(res.data.data.pages);
      })
      .catch(() => {
        setSectors([]);
      });
  }

  function isSelected() {
    return !![href.asx, href.organisation].includes(router.pathname);
  }

  return (
    <MegaMenu selected={isSelected()} href={href.markets} text="Markets">
      {/* ASX Market Data */}
      <MegaMenuSection
        key="mega-menu-section-market-data"
        title="ASX Market Data"
        linkHref={href.markets}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography color="textSecondary">
              Fresh Equities provides up to date ASX market data. View company
              profiles, sectors, indices and announcements.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Link href={href.markets}>
              <a className={classes.link}>ASX Market Data</a>
            </Link>
          </Grid>
          <Grid item xs={12}>
            <Link href={href.asx}>
              <a className={classes.link}>ASX Companies</a>
            </Link>
          </Grid>
          <Grid item xs={12}>
            <Link href={href.asxSectors}>
              <a className={classes.link}>ASX Sectors</a>
            </Link>
          </Grid>
          <Grid item xs={12}>
            <Link href={href.indices}>
              <a className={classes.link}>ASX Indices</a>
            </Link>
          </Grid>
        </Grid>
      </MegaMenuSection>
      {/* Sector Breakdowns */}
      <MegaMenuSection
        key="mega-menu-section-sector-breakdowns"
        title="Sector Breakdowns"
        linkAs={asPath.landingPageIndex('Sector breakdown')}
        linkHref={href.landingPageIndex}
      >
        <Grid container spacing={2}>
          {sectors.map((sector) => (
            <Grid item key={sector.slug} xs={12}>
              <Link
                href={href.landingPage}
                as={asPath.landingPage('Sector breakdown', sector.slug)}
              >
                <a className={classes.link}>{sector.title}</a>
              </Link>
            </Grid>
          ))}
        </Grid>
      </MegaMenuSection>
    </MegaMenu>
  );
};

export default Research;
