import { gql } from '@apollo/client';

export interface BasicMe {
  id: string;
  insertedAt: string;
  updatedAt: string;

  confirmedAt: string | null;
  confirmationSentAt: string | null;
  displayName: string | null;
  email: string;
  failedAttempts: number;
  firstName: string | null;
  invalidated: boolean;
  lastName: string | null;
  lockedAt: string | null;
  mobileNumber: string | null;
}

export interface BasicMeQueryData {
  me: BasicMe | null;
}

/**
 * This query will return the basic version of MeQuery.
 * It will only fetch default fields.
 */
const BasicMeQuery = gql`
  query MeQuery {
    me {
      id
      insertedAt
      updatedAt

      confirmedAt
      confirmationSentAt
      displayName
      email
      failedAttempts
      firstName
      invalidated
      lastName
      lockedAt
      mobileNumber
    }
  }
`;

export default BasicMeQuery;
