import React, { FC } from 'react';
// Modules
import { useRouter } from 'next/router';
import { useQuery } from '@apollo/client';
// MUI Core
import Grid from '@material-ui/core/Grid';
// FUI
import Typography from '../../../../fui/v2/Typography';
// Components
import MegaMenu from './MegaMenu';
import MegaMenuSection from './MegaMenuSection';
// GraphQL
import MeQuery, {
  MeQueryData,
} from '../../../../graphql-ts/queries/MeQuery.graphql';
// Utils
import { asPath, href } from '../../../../utils-ts/pages';

const Raises: FC = () => {
  const { data } = useQuery<MeQueryData>(MeQuery);

  const router = useRouter();

  function isSelected() {
    return !![
      href.capitalRaises,
      href.liveCapitalRaises,
      href.pastCapitalRaises,
    ].includes(router.pathname);
  }

  return (
    <MegaMenu
      selected={isSelected()}
      href={asPath.capitalRaises(!!(data && data.me))}
      text="Raises"
    >
      {/* Live Raises */}
      <MegaMenuSection
        key="mega-menu-section-live"
        title="Live Capital Raises"
        linkHref={asPath.capitalRaises(!!(data && data.me))}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography color="textSecondary">
              Fresh Equities offers Australia’s best access to over 400 listed
              capital raises a year for institutions, family office and high net
              worth clients.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography color="textSecondary">
              Participate in Placements and Shortfall Offers.
            </Typography>
          </Grid>
        </Grid>
      </MegaMenuSection>

      {/* Past Raises */}
      <MegaMenuSection
        key="mega-menu-section-past"
        title="Past Capital Raises"
        linkHref={href.pastCapitalRaises}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography color="textSecondary">
              Browse our comprehensive history of completed placements and
              shareholder offers for ASX listed companies. See when listed
              companies last raised capital, including details about the
              outcome.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography color="textSecondary">
              Find out which offers Fresh clients had access to.
            </Typography>
          </Grid>
        </Grid>
      </MegaMenuSection>
    </MegaMenu>
  );
};

export default Raises;
