import { StorageKey } from './constants';

export enum Step {
  ACCOUNT_TYPE = 'account-type',
  COMPLETED = 'completed',
  EMAIL_CONFIRM = 'email-confirm',
  ID_CONFIRM = 'id-confirm',
  ID_TYPE = 'id-type',
  LEGAL_NAME = 'legal-name',
  MOBILE_NUMBER = 'mobile-number',
  NEXT_STEP = 'next-step',
  PENDING_VERIFICATION = 'pending-verification',
  RESEARCH_CONFIRMATION = 'research-confirmation',
  STAT_DEC = 'stat-dec',
  S708_ACCOUNTANT_DETAILS = 'accountant-details',
  S708_VERIFICATION = 's708-verification',
}

export interface IDConfirmFormData {
  consent1: boolean;
  consent2: boolean;
  country: string;
  dateOfBirth: Date | null;
  documentNumber: string;
  firstName: string;
  idCountry: string;
  idNumber: string;
  idType: string;
  lastName: string;
  middleName: string;
  postalCode: string;
  state: string;
  streetName: string;
  streetNumber: string;
  streetType: string;
  suburb: string;
  unitNumber: string;
}

export function getAccountType() {
  const onboardingData = localStorage.getItem(StorageKey.ONBOARDING_DATA);
  const onboardingDataObject = onboardingData ? JSON.parse(onboardingData) : {};

  switch (onboardingDataObject.accountType) {
    case 'sophisticated':
      return 'sophisticated';

    case 'professional':
      return 'professional';

    default:
      return 'retail';
  }
}

export function getData(key: string, defaultValue: string) {
  const onboardingData = localStorage.getItem(StorageKey.ONBOARDING_DATA);
  const onboardingDataObject = onboardingData ? JSON.parse(onboardingData) : {};

  return onboardingDataObject[key] ?? defaultValue;
}

export function getIdType() {
  const onboardingData = localStorage.getItem(StorageKey.ONBOARDING_DATA);
  const onboardingDataObject = onboardingData ? JSON.parse(onboardingData) : {};

  switch (onboardingDataObject.idType) {
    case 'DRIVERS_LICENCE':
      return "Driver's Licence";

    case 'PASSPORT':
      return 'Passport';

    default:
      return 'Document';
  }
}

export function updateData(data: any) {
  const onboardingData = localStorage.getItem(StorageKey.ONBOARDING_DATA);
  const onboardingDataObject = onboardingData ? JSON.parse(onboardingData) : {};

  const newData = {
    ...onboardingDataObject,
    ...data,
  };

  localStorage.setItem(StorageKey.ONBOARDING_DATA, JSON.stringify(newData));
}

export function hasPastExperiencedBooks(
  pastBooks: { isExperienced: boolean | null }[],
) {
  return pastBooks.some((book) => !!book.isExperienced);
}
