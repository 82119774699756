import React, { FC } from 'react';
// Modules
import clsx from 'clsx';
import { useQuery } from '@apollo/client';
import Link from 'next/link';
// MUI Core
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
// Components
import NotificationBellDesktop from './notifications/NotificationBellDesktop';
import SearchBar from '../../common/SearchBar';
import UserMenu from './UserMenu';
// GraphQL
import BasicMeQuery, {
  BasicMeQueryData,
} from '../../../graphql-ts/queries/BasicMeQuery.graphql';
// FUI
import Button from '../../../fui/v2/Button';
// Utils
import { Color } from '../../../utils-ts/theme';
import { href } from '../../../utils-ts/pages';

const useStyles = makeStyles((theme) => ({
  appear: {
    transition: '.1s opacity ease',
    willChange: 'opacity',
    'html:not(.no-js) &': {
      opacity: 0,
      pointerEvents: 'none',
    },
  },
  appearIn: {
    'html:not(.no-js) &': {
      opacity: 1,
      pointerEvents: 'all',
    },
  },
  hiddenSmDown: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  flex1: {
    [theme.breakpoints.up('md')]: {
      flex: 1,
    },
  },
  searchContainer: {
    textAlign: 'right',
  },
  signUpButton: {
    minWidth: 160,
  },
  verticalDivider: {
    backgroundColor: Color.SMOKE,
    height: 35,
    width: 1,
  },
}));

const NavBarContent: FC = () => {
  const { data, loading } = useQuery<BasicMeQueryData>(BasicMeQuery);

  const classes = useStyles();

  return (
    <Grid
      item
      xs={6}
      md="auto"
      className={clsx(classes.hiddenSmDown, classes.flex1)}
    >
      <Grid
        container
        className={clsx(
          classes.appear,
          (data?.me || !loading) && classes.appearIn,
        )}
        alignItems="center"
        justify="flex-end"
        spacing={2}
        wrap="nowrap"
      >
        {/* Search Bar */}
        <Grid
          item
          md="auto"
          className={clsx(classes.flex1, classes.searchContainer)}
        >
          <SearchBar size="small" />
        </Grid>

        {/* Divider */}
        <Grid item>
          <div className={classes.verticalDivider} />
        </Grid>

        {/* Login or Account Info */}
        <Grid item>
          {data && data.me ? (
            <Grid container alignContent="center">
              <Grid item>
                <UserMenu me={data.me} />
              </Grid>
              <Grid item>
                <NotificationBellDesktop />
              </Grid>
            </Grid>
          ) : (
            <Grid container alignItems="center" spacing={2} wrap="nowrap">
              <Grid item>
                <Link href={href.authLogIn}>
                  <a>
                    <Button color="secondary" variant="outlined">
                      Login
                    </Button>
                  </a>
                </Link>
              </Grid>
              <Grid item>
                <Link href={href.authSignUp}>
                  <a>
                    <Button className={classes.signUpButton} color="secondary">
                      Become a client
                    </Button>
                  </a>
                </Link>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default NavBarContent;
