import React, { FC, useState } from 'react';
// Modules
import { useQuery } from '@apollo/client';
// MUI Core
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
// MUI Icons
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
// Components
import MobileMenu from './mobile/MobileMenu';
import MobileSearchDialog from './mobile/MobileSearchDialog';
import NotificationBellMobile from './notifications/NotificationBellMobile';
// GraphQL
import AmILoginQuery, {
  AmILoginQueryData,
} from '../../../graphql-ts/queries/AmILoginQuery.graphql';
// Utils
import routes from '../../../utils-ts/routes';

const useStyles = makeStyles((theme) => ({
  hiddenMdUp: {
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
}));

const NavBarContent: FC = () => {
  const { data } = useQuery<AmILoginQueryData, undefined>(AmILoginQuery);

  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [isMobileSearchDialogOpen, setMobileSearchDialogOpen] = useState(false);

  const classes = useStyles();

  return (
    <Grid item className={classes.hiddenMdUp}>
      <Grid container alignItems="center" spacing={1} wrap="nowrap">
        <Grid item>
          <IconButton
            edge="end"
            onClick={() => setMobileSearchDialogOpen(true)}
          >
            <SearchIcon />
          </IconButton>

          <MobileSearchDialog
            onClose={() => setMobileSearchDialogOpen(false)}
            open={isMobileSearchDialogOpen}
          />
        </Grid>
        {!!(data && data.me) && (
          <Grid item>
            <NotificationBellMobile logoHref={routes.liveCapitalRaises.href} />
          </Grid>
        )}
        <Grid item>
          <IconButton edge="end" onClick={() => setMobileMenuOpen(true)}>
            <MenuIcon />
          </IconButton>

          <MobileMenu
            onClose={() => setMobileMenuOpen(false)}
            open={isMobileMenuOpen}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default NavBarContent;
