import { gql } from '@apollo/client';
// GraphQL
import ListingFragment, { Listing } from '../fragments/ListingFragment.graphql';
import OrganisationGicsFragment, {
  OrganisationGics,
} from '../fragments/OrganisationGicsFragment.graphql';
// Utils
import { IOptions, IOptionsInput, IPageInfo } from '../../utils-ts/interfaces';

export interface OrganisationListItemLatestDebtInstrument {
  id: string;

  coupon: number | null;
  couponFrequency: 'annual' | 'semi-annual' | 'quarterly' | 'monthly' | null;
  couponType: 'fixed' | 'floating' | 'payable at maturity' | null;
  faceValue: number | null;
  maturityDate: string | null;
  type: string | null;
}

export interface OrganisationListItemLatestEquityInstrument {
  id: string;

  price: number | null;
  optionsAvailable: boolean;
  optionsExpiration: number | null;
  optionsListingCondition: string | null;
  optionsRatioDenominator: number | null;
  optionsRatioNumerator: number | null;
  optionsStrikePrice: number | null;
}

export interface OrganisationListItem {
  id: string;

  delistedAt: string | null;
  latestMarketCap: number | null;
  latestPercentageChange: number | null;
  latestPrice: number | null;
  name: string;
  summary: string | null;

  bannerUrl: string | null;

  gics: OrganisationGics;

  latestOffer: {
    id: string;
    offerAnnouncedAt: string;
    outcomeAnnouncedAt: string;
  } | null;

  logoThumbUrl: string | null;

  logoUrl: string | null;

  listings: Listing[];
}

export interface OrganisationListQueryData {
  organisationList: {
    edges: {
      node: OrganisationListItem;
    }[];
    options: IOptions;
    pageInfo: IPageInfo;
    total: number;
  };
}

export interface OrganisationListQueryVariables {
  after?: string;
  before?: string;
  first?: number;
  last?: number;
  options?: IOptionsInput;
}

export default gql`
  query OrganisationListQuery(
    $after: String
    $before: String
    $first: Int
    $last: Int
    $options: OptionsInput
  ) {
    organisationList(
      after: $after
      before: $before
      first: $first
      last: $last
      options: $options
    ) {
      edges {
        node {
          id

          delistedAt
          latestMarketCap
          latestPercentageChange
          latestPrice
          name
          summary

          bannerUrl

          gics {
            ...OrganisationGicsFragment
          }

          latestOffer {
            id
            offerAnnouncedAt
            outcomeAnnouncedAt
          }

          logoThumbUrl

          logoUrl

          listings {
            ...ListingFragment
          }
        }
      }
      options {
        filters {
          key
          value
        }
        orders {
          key
          value
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      total(options: $options)
    }
  }
  ${ListingFragment}
  ${OrganisationGicsFragment}
`;
