import { gql } from '@apollo/client';
// Utils
import { Flag } from '../../utils-ts/types';

export interface FeatureFlagUpsertedSubscriptionData {
  featureFlagUpserted: {
    enabled: boolean;
    key: Flag;
  };
}

const FeatureFlagUpsertedSubscription = gql`
  subscription FeatureFlagUpsertedSubscription {
    featureFlagUpserted {
      enabled
      key
    }
  }
`;

export default FeatureFlagUpsertedSubscription;
