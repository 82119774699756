import { gql } from '@apollo/client';

export interface FeatureOnboarding {
  id: string;
  insertedAt: string;
  updatedAt: string;

  featureName: string;
  onboardedAt: string | null;
}

const FeatureOnboardingFragment = gql`
  fragment FeatureOnboardingFragment on FeatureOnboarding {
    id
    insertedAt
    updatedAt

    featureName
    onboardedAt
  }
`;

export default FeatureOnboardingFragment;
