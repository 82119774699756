import React, { useState } from 'react';
// Modules
import groupBy from 'lodash/groupBy';
import { useQuery } from '@apollo/client';
// MUI core
import Badge from '@material-ui/core/Badge';
import IconButton from '@material-ui/core/IconButton';
import NotificationsIcon from '@material-ui/icons/Notifications';
// Components
import ActionRequiredModal from './ActionRequiredModal';
// GraphQL
import OngoingTransactionsQuery, {
  OngoingTransactionsQueryData,
  OngoingTransactionsQueryVariables,
} from '../../../../graphql-ts/queries/OngoingTransactionsQuery.graphql';

interface Props {
  logoHref: string;
  closeMenu?: () => void;
}
// TODO: Make notifications update live?
// TODO: Weird bug - the whole header shifts to the left when you press the bell
const NotificationBellMobile: React.FC<Props> = ({ logoHref, closeMenu }) => {
  const ongoingTransactions = useQuery<
    OngoingTransactionsQueryData,
    OngoingTransactionsQueryVariables
  >(OngoingTransactionsQuery, {
    fetchPolicy: 'cache-and-network',
  });
  const [showModal, setShowModal] = useState(false);

  const closeModals = () => {
    setShowModal(false);
    if (closeMenu) {
      closeMenu();
    }
  };

  const groupedTransactions = groupBy(
    ongoingTransactions.data?.ongoingTransactions ?? [],
    'actionRequired',
  );

  const actionRequiredTransactions = groupedTransactions.true ?? [];

  return (
    <>
      <IconButton edge="end" onClick={() => setShowModal(true)}>
        <Badge
          color="secondary"
          variant="dot"
          invisible={actionRequiredTransactions.length === 0}
        >
          <NotificationsIcon />
        </Badge>
      </IconButton>
      <ActionRequiredModal
        logoHref={logoHref}
        open={showModal}
        onClose={closeModals}
        transactions={actionRequiredTransactions}
      />
    </>
  );
};

export default NotificationBellMobile;
