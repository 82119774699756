import React, { FC, useContext } from 'react';
// Modules
import * as Sentry from '@sentry/nextjs';
import Link from 'next/link';
// MUI Core
import Avatar from '@material-ui/core/Avatar';
import { makeStyles } from '@material-ui/core/styles';
// MUI Icons
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
// Contexts
import SnackbarContext from '../../../contexts/SnackbarContext';
// FUI
import Typography from '../../../fui/v2/Typography';
// GraphQL
import { BasicMe } from '../../../graphql-ts/queries/BasicMeQuery.graphql';
// Utils
import { Color } from '../../../utils-ts/theme';
import { getErrorMessage } from '../../../utils-ts/helper';
import { href } from '../../../utils-ts/pages';
import routes from '../../../utils-ts/routes';

interface UserMenuProps {
  me: BasicMe;
}

const useStyles = makeStyles((theme) => ({
  accountButton: {
    alignItems: 'center',
    backgroundColor: theme.palette.background.default,
    color: Color.STONE,
    cursor: 'pointer',
    display: 'flex',
    height: 48,
    padding: '0px 16px',
    transition: 'all .1s ease-in',
  },
  accountButtonContainer: {
    position: 'relative',
    '&:hover $dropdown': {
      transition: 'all .15s ease-out',
      opacity: 1,
      pointerEvents: 'all',
      transform: 'translateY(0)',
    },
    '&:hover $accountButton': {
      backgroundColor: Color.GREY,
    },
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.background.default,
    fontSize: 14,
    fontWeight: 500,
    height: 32,
    marginLeft: theme.spacing(),
    width: 32,
  },
  dropdown: {
    position: 'absolute',
    border: `1px solid ${theme.palette.divider}`,
    top: '100%',
    right: 0,
    opacity: 0,
    transform: 'translateY(-15px)',
    pointerEvents: 'none',
    minWidth: 155,
    [theme.breakpoints.up('lg')]: {
      minWidth: 170,
      left: 0,
      right: 'auto',
    },
  },
  dropdownLink: {
    position: 'relative',
    backgroundColor: theme.palette.background.default,
    color: Color.STONE,
    display: 'block',
    padding: theme.spacing(2),
    transition: 'all .1s ease',
    whiteSpace: 'nowrap',
    '&:hover': {
      color: Color.PINE,
    },
    '&:after': {
      content: "''",
      position: 'absolute',
      bottom: 0,
      left: theme.spacing(2),
      right: theme.spacing(2),
      borderBottom: `1px solid ${theme.palette.divider}`,
      zIndex: 1,
    },
    '&:last-of-type:after': {
      display: 'none',
    },
  },
  logOutIcon: {
    marginRight: theme.spacing(),
  },
  logOutMenu: {
    alignItems: 'center',
    display: 'flex',
  },
}));

const UserMenu: FC<UserMenuProps> = ({ me }) => {
  const { showSnackbar } = useContext(SnackbarContext);

  const classes = useStyles();

  function getUserInitial() {
    if (me.firstName && me.lastName) {
      return `${me.firstName.charAt(0).toUpperCase()}${me.lastName
        .charAt(0)
        .toUpperCase()}`;
    }

    return me.email.charAt(0).toUpperCase();
  }

  async function onClickLogOut() {
    try {
      await fetch(`${process.env.NEXT_PUBLIC_AUTH_URL}/logout`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      });

      localStorage.clear();

      // window.analytics?.reset();

      window.location.assign(href.home);
    } catch (error) {
      showSnackbar(getErrorMessage(error), 'error');

      Sentry.captureException(error);
    }
  }

  return (
    <div className={classes.accountButtonContainer}>
      <Link href={href.profile}>
        <a className={classes.accountButton} role="button" tabIndex={0}>
          <Typography color="inherit" noWrap variant="button">
            Account
          </Typography>
          <Avatar className={classes.avatar}>{getUserInitial()}</Avatar>
        </a>
      </Link>

      <div className={classes.dropdown}>
        <Link href={href.profile}>
          <a className={classes.dropdownLink}>
            <Typography variant="button">Manage account</Typography>
          </a>
        </Link>
        <Link href={routes.accountTransactions.href}>
          <a className={classes.dropdownLink}>
            <Typography variant="button">Transactions</Typography>
          </a>
        </Link>
        <Link href={href.contactUs}>
          <a className={classes.dropdownLink}>
            <Typography variant="button">Contact us</Typography>
          </a>
        </Link>
        <a
          href={href.profile}
          className={classes.dropdownLink}
          onClick={(e) => {
            e.preventDefault();
            onClickLogOut();
          }}
        >
          <Typography className={classes.logOutMenu} variant="button">
            <ExitToAppIcon
              className={classes.logOutIcon}
              color="inherit"
              fontSize="inherit"
            />
            Log out
          </Typography>
        </a>
      </div>
    </div>
  );
};

export default UserMenu;
