import React, { FC, useEffect, useRef, useState } from 'react';
// Modules
import clsx from 'clsx';
import Link from 'next/link';
// MUI Core
import { makeStyles, Theme } from '@material-ui/core/styles';
// Components
import { NewFeatureNotifierSmall } from '../../../common/NewFeatureNotifier';
// FUI
import Typography from '../../../../fui/v2/Typography';
// Utils
import { Color } from '../../../../utils-ts/theme';

interface MegaMenuProps {
  href: string;
  leftContent?: React.ReactNode;
  logoRef?: React.RefObject<HTMLDivElement>;
  newFeature?: boolean;
  selected: boolean;
  text: string;
}

const useStyles = makeStyles<
  Theme,
  { leftContentLeft: string; wrapLeft: string }
>((theme) => ({
  leftContent: {
    position: 'absolute',
    left: ({ leftContentLeft }) => leftContentLeft,
    width: 240,
    padding: '32px 0',
  },
  link: {
    alignItems: 'center',
    color: Color.STONE,
    display: 'flex',
    outline: 'none',
    height: 70,
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(1),
    transition: 'all .1s ease-out',
    '&:after': {
      content: "''",
      display: 'block',
      position: 'absolute',
      bottom: theme.spacing(3),
      left: theme.spacing(2),
      borderBottom: `1px solid ${Color.FRESH}`,
      opacity: 0,
      transition: 'inherit',
      width: 0,
    },
  },
  linkActive: {
    color: theme.palette.secondary.dark,
    '&:after': {
      opacity: 1,
      width: `calc(100% - ${theme.spacing(4)}px)`,
    },
  },
  linkIcon: {
    marginLeft: theme.spacing(2),
  },
  linkWrap: {
    position: 'relative',
    zIndex: theme.zIndex.appBar,
    '&:hover': {
      zIndex: theme.zIndex.appBar + 1,
    },
    '&:hover > div': {
      opacity: 1,
      pointerEvents: 'all',
      transform: 'translateY(0px)',
      transition: 'all .15s ease-out',
    },
    '&:hover $link': {
      backgroundColor: Color.GREY,
      color: theme.palette.text.primary,
    },
  },
  megaMenu: {
    position: 'fixed',
    left: 0,
    right: 0,
    top: 126,
    width: '100%',
    backgroundColor: 'transparent',
    height: 328,
    minHeight: 328,
    opacity: 0,
    pointerEvents: 'none',
    cursor: 'auto',
    transform: 'translateY(-5px)',
    zIndex: theme.zIndex.appBar - 1,
  },
  notifier: {
    position: 'absolute',
    top: 3,
    left: -22,
  },
  text: {
    paddingBottom: 6,
    paddingTop: 6,
  },
  textWrap: {
    position: 'relative',
  },
  wrap: {
    position: 'absolute',
    left: ({ wrapLeft }) => wrapLeft,
    right: 0,
    height: '100%',
  },
}));

const MegaMenu: FC<MegaMenuProps> = ({
  children,
  href,
  leftContent,
  logoRef,
  newFeature,
  selected,
  text,
}) => {
  const linkRef = useRef<HTMLDivElement>(null);
  const wrapRef = useRef<HTMLDivElement>(null);
  const leftContentRef = useRef<HTMLDivElement>(null);

  const [wrapLeft, setWrapLeft] = useState('0px');
  const [leftContentLeft, setLeftContentLeft] = useState('0px');

  const classes = useStyles({ leftContentLeft, wrapLeft });

  function positionItems() {
    if (linkRef.current && wrapRef.current) {
      setWrapLeft(`${linkRef.current.offsetLeft}px`);
    }
    if (logoRef && logoRef.current && leftContentRef.current) {
      setLeftContentLeft(`${logoRef.current.offsetLeft}px`);
    }
  }

  useEffect(() => {
    positionItems();
    window.addEventListener('resize', () => positionItems());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={classes.linkWrap} ref={linkRef}>
      <Link href={href}>
        <a className={clsx(classes.link, selected ? classes.linkActive : '')}>
          <span className={classes.textWrap}>
            {newFeature && (
              <NewFeatureNotifierSmall
                overlap="circle"
                variant="dot"
                className={classes.notifier}
              >
                {/* eslint-disable-next-line react/jsx-curly-brace-presence */}
                {''}
              </NewFeatureNotifierSmall>
            )}
            <Typography
              color="inherit"
              className={classes.text}
              variant="button-sm"
            >
              {text}
            </Typography>
          </span>
        </a>
      </Link>
      {!!children && (
        <div className={classes.megaMenu}>
          {!!leftContent && (
            <div className={classes.leftContent} ref={leftContentRef}>
              {leftContent}
            </div>
          )}
          <nav className={classes.wrap} ref={wrapRef} role="navigation">
            {children}
          </nav>
        </div>
      )}
    </div>
  );
};

export default MegaMenu;
