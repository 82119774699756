import dayjs from 'dayjs';

export function formatTimeline(time: string) {
  const timeToCheck = dayjs(time);

  const isSameDay = dayjs().isSame(timeToCheck, 'day');
  const isTomorrow = dayjs().add(1, 'day').isSame(timeToCheck, 'day');
  const isYesterday = dayjs().subtract(1, 'day').isSame(timeToCheck, 'day');

  if (isSameDay) {
    return `today ${timeToCheck.format('h:mmA')}`;
  }

  if (isTomorrow) {
    return `tomorrow ${timeToCheck.format('h:mmA')}`;
  }

  if (isYesterday) {
    return `yesterday ${timeToCheck.format('h:mmA')}`;
  }

  return `${timeToCheck.format('D MMMM YYYY h:mmA')}`;
}

export function getOfferHeaderTime(time: string | null) {
  if (!time) {
    return '-';
  }
  const timeToCheck = dayjs(time);
  const isPast = timeToCheck.isBefore(dayjs().subtract(1, 'day'), 'day');
  const isYesterday = timeToCheck.isSame(dayjs().subtract(1, 'day'), 'day');
  const isToday = timeToCheck.isSame(dayjs(), 'day');
  const isTomorrow = timeToCheck.isSame(dayjs().add(1, 'day'), 'day');
  const isFuture = timeToCheck.isAfter(dayjs().add(1, 'day'), 'day');

  if (isPast) {
    return `${timeToCheck.format('DD MMM YYYY')} `;
  }
  if (isYesterday) {
    return `${timeToCheck.format('h:mmA [yesterday]')}`;
  }
  if (isToday) {
    return `${timeToCheck.format('h:mmA [today]')}`;
  }
  if (isTomorrow) {
    return `${timeToCheck.format('h:mmA [tomorrow]')} `;
  }
  if (isFuture) {
    return `${timeToCheck.format('DD MMM YYYY [at] h:mmA')} `;
  }
  return `${timeToCheck.format('dddd h:mmA')} `;
}
