import React from 'react';
// Modules
import clsx from 'clsx';
import Link from 'next/link';
import { useRouter } from 'next/router';
// MUI Core
import Container from '@material-ui/core/Container';
import Step from '@material-ui/core/Step';
import StepConnector from '@material-ui/core/StepConnector';
import { StepIconProps } from '@material-ui/core/StepIcon';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import { makeStyles, withStyles } from '@material-ui/core/styles';
// MUI Icons
import Check from '@material-ui/icons/Check';
// Components
import { OnboardingIcon, OnboardingNavBarIcon } from './OnboardingNavBarIcons';
// Utils
import { Step as OnboardingStep } from '../../../utils-ts/onboarding-helper';
import { href } from '../../../utils-ts/pages';
import theme, { Color } from '../../../utils-ts/theme';

import routes from '../../../utils-ts/routes';

const useStyles = makeStyles({
  container: {
    padding: theme.spacing(3),
  },
  content: {
    position: 'relative',
  },
  flex: {
    display: 'flex',
  },
  header: {
    backgroundColor: theme.palette.background.default,
    borderBottom: `1px solid ${theme.palette.divider}`,
    left: 0,
    position: 'fixed',
    right: 0,
    top: 0,
    zIndex: theme.zIndex.appBar,
  },
  labelContainer: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  logo: {
    height: 48,
    objectFit: 'contain',
    width: 80,
  },
  shim: {
    minHeight: 97,
  },
  stepContainer: {
    bottom: 0,
    left: 0,
    pointerEvents: 'none',
    position: 'absolute',
    right: 0,
    top: '50%',
    transform: 'translateY(-50%)',
    [theme.breakpoints.down('sm')]: {
      left: 114,
    },
  },
  stepContent: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
    margin: 'auto',
    maxWidth: 480,
  },
  stepper: {
    backgroundColor: 'transparent',
    padding: 0,
  },
});

const QontoConnector = withStyles({
  alternativeLabel: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  active: {
    '& $line': {
      borderColor: theme.palette.secondary.main,
    },
  },
  completed: {
    '& $line': {
      borderColor: theme.palette.secondary.main,
    },
  },
  line: {
    borderColor: theme.palette.divider,
    borderTopWidth: 3,
    borderRadius: 1,
  },
})(StepConnector);

const useQontoStepIconStyles = makeStyles({
  root: {
    color: Color.SILVER,
    display: 'flex',
    height: 22,
    alignItems: 'center',
  },
  active: {
    color: theme.palette.secondary.main,
  },
  circle: {
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
  },
  completed: {
    color: theme.palette.secondary.main,
    zIndex: 1,
    fontSize: 18,
  },
});

interface QontoStepIconProps extends StepIconProps {
  iconName?: OnboardingIcon;
}

function QontoStepIcon(props: QontoStepIconProps) {
  const classes = useQontoStepIconStyles();
  const { active, completed, iconName } = props;
  const renderContent = () => {
    if (completed) {
      return <Check className={classes.completed} />;
    }
    if (iconName) {
      return (
        <>
          <OnboardingNavBarIcon
            className="block lg:hidden"
            iconName={iconName}
          />
          <div className={clsx(classes.circle, 'hidden lg:block')} />
        </>
      );
    }
    return <div className={classes.circle} />;
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
      })}
    >
      {renderContent()}
    </div>
  );
}

const OnboardingNavBar: React.FC = () => {
  const {
    pathname,
    query: { progress, step },
  } = useRouter();
  const activeStep = React.useMemo(() => {
    if (pathname !== routes.identityCheck.href || progress === 'hidden')
      return -1;

    switch (step) {
      case OnboardingStep.LEGAL_NAME:
        return 0;
      case OnboardingStep.ID_TYPE:
        return 1;
      case OnboardingStep.ID_CONFIRM:
        return 2;
      default:
        return -1;
    }
  }, [pathname, progress, step]);

  const classes = useStyles();

  return (
    <>
      <header className={classes.header}>
        <div className={classes.content}>
          <Container className={classes.container}>
            <div className={classes.flex}>
              <Link href={href.home}>
                <a className={classes.flex}>
                  {/* eslint-disable-next-line @next/next/no-img-element */}
                  <img
                    className={classes.logo}
                    alt="Fresh Equities"
                    height="48"
                    src="/images/assets/logo-onlight-xmas.svg"
                    width="80"
                  />
                </a>
              </Link>
            </div>
          </Container>

          {activeStep !== -1 && (
            <div className={classes.stepContainer}>
              <div className={classes.stepContent}>
                <Stepper
                  alternativeLabel
                  activeStep={activeStep}
                  classes={{ root: classes.stepper }}
                  connector={<QontoConnector />}
                >
                  <Step>
                    <StepLabel
                      classes={{ labelContainer: classes.labelContainer }}
                      StepIconComponent={(props) => (
                        <QontoStepIcon {...props} />
                      )}
                    >
                      Confirm name
                    </StepLabel>
                  </Step>
                  <Step>
                    <StepLabel
                      classes={{ labelContainer: classes.labelContainer }}
                      StepIconComponent={(props) => (
                        <QontoStepIcon {...props} />
                      )}
                    >
                      Choose ID
                    </StepLabel>
                  </Step>
                  <Step>
                    <StepLabel
                      classes={{ labelContainer: classes.labelContainer }}
                      StepIconComponent={(props) => (
                        <QontoStepIcon {...props} />
                      )}
                    >
                      Confirm identity
                    </StepLabel>
                  </Step>
                </Stepper>
              </div>
            </div>
          )}
        </div>
      </header>
      <div className={classes.shim} />
    </>
  );
};

export default OnboardingNavBar;
