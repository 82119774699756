import React, { FC, useEffect, useState } from 'react';
// Modules
import axios from 'axios';
import Link from 'next/link';
import { useRouter } from 'next/router';
// MUI Core
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
// FUI
import ArrowLink from '../../../../fui/v2/ArrowLink';
import Typography from '../../../../fui/v2/Typography';
// Components
import MegaMenu from './MegaMenu';
import MegaMenuSection from './MegaMenuSection';
// Utils
import { DynamicLandingPage } from '../../../../utils-ts/contentful/landingPages';
import { Post } from '../../../../utils-ts/contentful/base';
import { asPath, href } from '../../../../utils-ts/pages';
// import routes from '../../../../utils-ts/routes';
// import { ESG_DESCRIPTION } from '../../../fresh-impact/JourneySection';

interface LearnProps {
  logoRef: React.RefObject<HTMLDivElement>;
}

const useStyles = makeStyles((theme) => ({
  link: {
    color: theme.palette.secondary.dark,
    fontWeight: 500,
    fontSize: 14,
  },
}));

const Learn: FC<LearnProps> = ({ logoRef }) => {
  const classes = useStyles();
  const router = useRouter();

  const [useCases, setUseCases] = useState<DynamicLandingPage[]>([]);
  const [featuredPosts, setFeaturedPosts] = useState<Post[]>([]);

  useEffect(() => {
    fetchUseCases();
    fetchFeaturedPosts();
  }, []);

  function fetchUseCases() {
    axios
      .get('/api/use-cases')
      .then((res) => {
        setUseCases(res.data.data.pages || []);
      })
      .catch(() => {
        setUseCases([]);
      });
  }

  function fetchFeaturedPosts() {
    axios
      .get('/api/menu-posts')
      .then((res) => {
        setFeaturedPosts(res.data.data.posts || []);
      })
      .catch(() => {
        setFeaturedPosts([]);
      });
  }

  function isSelected() {
    return !!(
      [href.faq].includes(router.pathname) ||
      router.asPath.match(/^\/resource-centre/)
    );

    // return !!(
    //   [href.about, href.faq].includes(router.pathname) ||
    //   router.asPath.match(/^\/resource-centre/)
    // );
  }

  function leftContent(): React.ReactNode {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="button-sm">
            Looking for more information?
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography color="textSecondary">
            Contact our responsive and friendly client services team any time.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Link href={href.contactUs}>
            <a className={classes.link}>Contact us</a>
          </Link>
        </Grid>
      </Grid>
    );
  }

  return (
    <MegaMenu
      selected={isSelected()}
      href={href.landingPageIndex}
      // href={href.about}
      text="Learn"
      leftContent={leftContent()}
      logoRef={logoRef}
    >
      {/* About Us */}
      {/* <MegaMenuSection
        key="mega-menu-section-about"
        title="About Us"
        linkHref={href.about}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography color="textSecondary">
              Fresh Equities started as a way to help investors get broader
              access to companies they care about. We’re working towards making
              participating in capital raises fair and transparent.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography color="textSecondary">
              Meet the team and vision behind Fresh.
            </Typography>
          </Grid>
        </Grid>
      </MegaMenuSection> */}

      {/* Use Cases */}
      <MegaMenuSection
        key="mega-menu-section-use-cases"
        linkAs={asPath.landingPageIndex('Use case')}
        linkHref={href.landingPageIndex}
        title="Use Cases"
      >
        <Grid container spacing={2}>
          {useCases.map((useCase) => (
            <Grid item key={useCase.slug} xs={12}>
              <Link
                href={href.landingPage}
                as={asPath.landingPage('Use case', useCase.slug)}
              >
                <a className={classes.link}>{useCase.title}</a>
              </Link>
            </Grid>
          ))}
        </Grid>
      </MegaMenuSection>

      {/* Resource Centre */}
      <MegaMenuSection
        key="mega-menu-section-resource-centre"
        title="Resource Centre"
        linkHref={href.resourceCentre}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Typography color="textSecondary">Popular articles</Typography>
              </Grid>
              {featuredPosts.map((post) => (
                <Grid item key={post.slug} xs={12}>
                  <Link href={href.post} as={asPath.post(post.slug)}>
                    <a className={classes.link}>{post.title}</a>
                  </Link>
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <ArrowLink
              href={href.resourceCentre}
              size="small"
              text="See all articles"
            />
          </Grid>
        </Grid>
      </MegaMenuSection>

      {/* ESG */}
      {/* <MegaMenuSection
        key="mega-menu-section-esg"
        title="Fresh Impact"
        linkHref={routes.freshImpact.href}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography color="textSecondary" className="mb-4">
              {ESG_DESCRIPTION}
            </Typography>
          </Grid>
        </Grid>
      </MegaMenuSection> */}

      {/* FAQs */}
      <MegaMenuSection
        key="mega-menu-section-faqs"
        title="FAQs"
        linkHref={href.faq}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography color="textSecondary">
              Find answers to frequently asked questions about Fresh and capital
              markets.
            </Typography>
          </Grid>
        </Grid>
      </MegaMenuSection>
    </MegaMenu>
  );
};

export default Learn;
