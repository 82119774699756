import { gql } from '@apollo/client';
// GraphQL
import FeatureOnboardingFragment, {
  FeatureOnboarding,
} from '../fragments/FeatureOnboardingFragment.graphql';
// Utils
import { FeatureOnboardingName } from '../../utils-ts/types';

export interface MarkFeatureOnboardingMutationData {
  markFeatureOnboarding: FeatureOnboarding;
}

export interface MarkFeatureOnboardingMutationVariables {
  featureName: FeatureOnboardingName;
}

export default gql`
  mutation MarkFeatureOnboardingMutation($featureName: String!) {
    markFeatureOnboarding(featureName: $featureName) {
      ...FeatureOnboardingFragment
    }
  }
  ${FeatureOnboardingFragment}
`;
