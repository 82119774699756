import { gql } from '@apollo/client';
// GraphQL
import FeatureOnboardingFragment, {
  FeatureOnboarding,
} from '../fragments/FeatureOnboardingFragment.graphql';

export interface MeFeatureOnboardingsQueryData {
  meFeatureOnboardings: [FeatureOnboarding];
}

export interface MeFeatureOnboardingsQueryVariables {}

export default gql`
  query MeFeatureOnboardingsQuery {
    meFeatureOnboardings {
      ...FeatureOnboardingFragment
    }
  }
  ${FeatureOnboardingFragment}
`;
