import { ShareholderVerificationStep } from './constants';

const routes = {
  // about: {
  //   href: '/about',
  // },
  account: {
    href: '/account',
  },
  accountInvestorQualification: {
    href: '/account/investor-qualification',
  },
  accountTransactions: {
    href: '/account/transactions',
  },
  authLogIn: {
    href: (next?: string) =>
      next ? `/auth/login?next=${next}` : '/auth/login',
  },
  authSignUp: {
    href: '/auth/signup',
  },
  authSignUpEmail: {
    href: (emailAddress?: string) =>
      emailAddress ? `/auth/signup?email=${emailAddress}` : '/auth/signup',
  },
  bidReceipt: {
    href: (offerHashId: string, confirmedBidId?: string) =>
      confirmedBidId
        ? `/capital-raises/${offerHashId}/bid-receipt?confirmedBidId=${confirmedBidId}`
        : `/capital-raises/${offerHashId}/bid-receipt`,
  },
  capitalRaises: {
    href: '/capital-raises',
  },
  company: {
    href: (exchangeKey: string, listingKey: string, utmSource?: 'offer') =>
      utmSource
        ? `/${exchangeKey}/${listingKey}?utm_source=${utmSource}`
        : `/${exchangeKey}/${listingKey}`,
  },
  confirmBid: {
    href: (bidId: string) => `/confirm-bid/${bidId}`,
  },
  verifyEmail: {
    href: `/verify-email`,
  },
  verifyMobile: {
    href: `/verify-mobile`,
  },
  contactUs: {
    href: '/contact-us',
  },
  createBiddingEntity: {
    href: '/bidding-entity/new',
  },
  // freshImpact: {
  //   href: '/fresh-impact',
  // },
  evtac: {
    href: '/electronic-verification-terms-and-conditions',
  },
  existingShareholderVerification: {
    href: (offerHashId: string, step?: ShareholderVerificationStep) =>
      step
        ? `/capital-raises/${offerHashId}/verification?step=${step}`
        : `/capital-raises/${offerHashId}/verification`,
  },
  external: {
    blog: (email?: string) =>
      email
        ? `https://new.freshequities.com?email=${email}`
        : 'https://new.freshequities.com',
  },
  home: {
    href: '/',
  },
  identityCheck: {
    href: '/identity-check',
  },
  ipo: {
    href: '/ipo',
  },
  ipoTermsAndConditions: {
    href: '/ipo-terms-and-conditions',
  },
  liveCapitalRaises: {
    href: '/live-capital-raises',
  },
  offer: {
    href: (offerHashId: string) => `/capital-raises/${offerHashId}`,
  },
  offerInvitation: {
    href: (offerHashId: string) => `/capital-raises/${offerHashId}/invitation`,
  },
  offerLetter: {
    href: (offerLetterId: string) => `/offer-letter/${offerLetterId}`,
  },
  pastCapitalRaises: {
    href: '/past-capital-raises',
  },
  post: {
    href: (slug: string) => `/resource-centre/${slug}`,
  },
  profile: {
    href: '/profile',
  },
  resourceCentre: {
    href: '/resource-centre',
  },
  unlockThisOffer: {
    href: (offerHashId: string) =>
      `/capital-raises/${offerHashId}/unlock-offer`,
  },
  verifyInvestorQualification: {
    href: '/verify-investor-qualification',
  },
};

export default routes;
