import { useQuery, useSubscription } from '@apollo/client';
import * as Sentry from '@sentry/nextjs';
// GraphQL
import FlagQuery, {
  FlagQueryData,
  FlagQueryVariables,
} from '../../graphql-ts/queries/FlagQuery.graphql';
import FeatureFlagUpsertedSubscription, {
  FeatureFlagUpsertedSubscriptionData,
} from '../../graphql-ts/subscriptions/FeatureFlagUpsertedSubscription.graphql';
// Utils
import { Flag } from '../../utils-ts/types';

function useFlag(key: Flag) {
  const { data, loading, refetch } = useQuery<
    FlagQueryData,
    FlagQueryVariables
  >(FlagQuery, {
    variables: {
      key,
    },
  });

  useSubscription<FeatureFlagUpsertedSubscriptionData>(
    FeatureFlagUpsertedSubscription,
    {
      onSubscriptionData: async () => {
        try {
          await refetch();
        } catch (error) {
          Sentry.captureException(error);
        }
      },
    },
  );

  return {
    enabled: !!data?.flag,
    loading,
  };
}

export default useFlag;
