import React from 'react';
// Modules
import Link from 'next/link';
// MUI core
import Box from '@material-ui/core/Box';
import CloseIcon from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import Slide from '@material-ui/core/Slide';
import { TransitionProps } from '@material-ui/core/transitions';
// FUI
import Typography from '../../../../fui/v2/Typography';
// GraphQL
import ActionRequiredContainer from './ActionRequiredContainer';
import { Transaction } from '../../../../graphql-ts/queries/OngoingTransactionsQuery.graphql';
// Utils
import { Color } from '../../../../utils-ts/theme';

interface Props {
  logoHref: string;
  transactions: Transaction[];
  onClose: () => void;
  open: boolean;
}
const useStyles = makeStyles((theme) => ({
  actionRequired: {
    borderBottom: `1px solid ${Color.SILVER}`,
  },
  flex: {
    display: 'flex',
  },
  logo: {
    height: 32,
    marginRight: theme.spacing(4),
    width: 'auto',
  },
}));

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const ActionRequiredModal: React.FC<Props> = ({
  logoHref,
  transactions,
  open,
  onClose,
}) => {
  const classes = useStyles();

  return (
    <Dialog
      fullScreen
      fullWidth
      onClose={onClose}
      open={open}
      TransitionComponent={Transition}
    >
      <Grid container>
        <Grid item xs={12}>
          <Box p={2}>
            <Grid
              container
              alignItems="center"
              justify="space-between"
              spacing={2}
            >
              {/* Logo */}
              <Grid item>
                <Link href={logoHref}>
                  <a className={classes.flex}>
                    {/* eslint-disable-next-line @next/next/no-img-element */}
                    <img
                      className={classes.logo}
                      alt="Fresh Equities"
                      src="/images/assets/logo-horizontal-green.svg"
                    />
                  </a>
                </Link>
              </Grid>
              <Grid item>
                {/* Close Button */}
                <IconButton edge="end" onClick={onClose}>
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={12}>
              <Box p={2} className={classes.actionRequired}>
                <Typography variant="heading-sm">Action required</Typography>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <ActionRequiredContainer
                isModal
                onClose={onClose}
                transactions={transactions}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default ActionRequiredModal;
