import { gql } from '@apollo/client';

export interface Listing {
  id: string;

  key: string;

  exchange: {
    id: string;

    key: string;
  };
}

const ListingFragment = gql`
  fragment ListingFragment on Listing {
    id

    key

    exchange {
      id

      key
    }
  }
`;

export default ListingFragment;
