// Modules
import Link from 'next/link';
import Image from 'next/image';
import Highlighter from 'react-highlighter';
// FUI
import Typography from '../../fui/v2/Typography';
// GraphQL
import { OrganisationListItem } from '../../graphql-ts/queries/OrganisationListQuery.graphql';
// Images
import logoPlaceholder from '../../public/images/assets/logo-placeholder.svg';
// Utils
import { getOrganisationCodes } from '../../utils-ts/helper';
import { asPath } from '../../utils-ts/pages';

export const CompanyCardLiteSkeleton: React.ComponentType = () => (
  <div className="flex overflow-hidden items-center p-4 space-x-4 bg-white rounded-lg border border-silver">
    <div className="w-14 h-14 bg-silver rounded animate-pulse" />
    <div className="flex-1 min-w-0">
      <div className="py-1 h-6">
        <div className="h-full bg-silver rounded animate-pulse" />
      </div>
      <div className="py-[3px] h-5">
        <div className="w-[20%] h-full bg-silver rounded animate-pulse" />
      </div>
      <div className="py-[3px] h-5">
        <div className="w-[40%] h-full bg-silver rounded animate-pulse" />
      </div>
    </div>
  </div>
);

interface Props {
  organisation: OrganisationListItem;
  search?: string;
}

const CompanyCardLite: React.ComponentType<Props> = ({
  organisation,
  search,
}) => {
  const exchangeKey = organisation.listings[0].exchange.key;
  const listingKey = organisation.listings[0].key;

  return (
    <Link href={asPath.organisation(exchangeKey, listingKey)}>
      <a>
        <div className="flex overflow-hidden items-center p-4 space-x-4 bg-white rounded-lg border border-silver transition-all hover:shadow-01">
          <div className="overflow-hidden relative w-14 h-14 bg-white rounded border border-silver">
            <Image
              alt=""
              layout="fill"
              src={organisation.logoThumbUrl ?? logoPlaceholder}
            />
          </div>
          <div className="flex-1 min-w-0">
            <Typography noWrap variant="body-lg">
              <Highlighter
                matchClass="bg-white font-bold text-jet"
                search={search}
              >
                {organisation.name}
              </Highlighter>
            </Typography>
            <Typography noWrap className="text-stone">
              <Highlighter
                matchClass="bg-white font-bold text-stone"
                search={search}
              >
                {getOrganisationCodes(organisation)}
              </Highlighter>
            </Typography>
            <Typography noWrap className="text-ash">
              <Highlighter
                matchClass="bg-white font-bold text-ash"
                search={search}
              >
                {organisation.gics.sector}
              </Highlighter>
            </Typography>
          </div>
        </div>
      </a>
    </Link>
  );
};

export default CompanyCardLite;
