import { useState } from 'react';
// Components
import ActionItem from './ActionItem';
import NotificationEmpty from './NotificationEmpty';
// FUI
import Typography from '../../../../fui/v2/Typography';
// GraphQL
import { Transaction } from '../../../../graphql-ts/queries/OngoingTransactionsQuery.graphql';

interface Props {
  isModal?: boolean;
  onClose?: () => void;
  transactions: Transaction[];
}

const ActionRequiredContainer: React.ComponentType<Props> = ({
  isModal,
  onClose,
  transactions,
}) => {
  const [showMore, setShowMore] = useState(false);

  const renderShowMore = () => {
    const firstFiveActions = transactions.slice(0, 5);
    if (showMore) {
      return (
        <div>
          {/* TODO: This can have an issue of duplicate keys
            since same offer can have multiple actions */}
          {transactions.map((transaction: Transaction, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <div key={index.toString() + transaction.raiseOffer.id}>
              <ActionItem onClose={onClose} transaction={transaction} />
              <div className="h-px bg-silver" />
            </div>
          ))}
        </div>
      );
    }
    return (
      <div>
        {firstFiveActions.map((transaction: Transaction, index) => (
          <div key={index.toString() + transaction.raiseOffer.id}>
            <ActionItem onClose={onClose} transaction={transaction} />
            <div className="h-px bg-silver" />
          </div>
        ))}
        <div>
          <a>
            <div className="p-4" onClick={() => setShowMore(true)}>
              <Typography className="text-pine" variant="body-bold-sm">
                Load more
              </Typography>
            </div>
          </a>
        </div>
      </div>
    );
  };

  // If there are actions required
  const renderDefault = () => (
    <div>
      {transactions.map((transaction: Transaction, index) => (
        <div key={index.toString() + transaction.raiseOffer.id}>
          <ActionItem onClose={onClose} transaction={transaction} />
          <div className="h-px bg-silver" />
        </div>
      ))}
    </div>
  );

  const renderContents = () => {
    if (transactions.length !== 0) {
      if (transactions.length > 5) {
        return renderShowMore();
      }
      return renderDefault();
    }
    return <NotificationEmpty />;
  };

  return (
    <div
      className={
        !isModal && !(transactions.length === 0)
          ? 'max-h-[440px] overflow-y-scroll'
          : ''
      }
    >
      {renderContents()}
    </div>
  );
};

export default ActionRequiredContainer;
