import React from 'react';
// Modules
import Image from 'next/image';
import orderBy from 'lodash/orderBy';
// MUI Core
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
// FUI
import { UrlObject } from 'url';
import { useRouter } from 'next/router';
import Typography from '../../../../fui/v2/Typography';
// GraphQL
import { Transaction } from '../../../../graphql-ts/queries/OngoingTransactionsQuery.graphql';
// Utils
// import { EVENTS } from '../../../../utils-ts/analytics';
import { Color, Shadow } from '../../../../utils-ts/theme';
import { formatTimeline } from '../../../../utils-ts/time-helper';
import { RaiseType } from '../../../../utils-ts/interfaces';
import routes from '../../../../utils-ts/routes';

interface Props {
  transaction: Transaction;
  onClose?: () => void;
}

const useStyles = makeStyles((theme) => ({
  root: {
    cursor: 'pointer',
    paddingTop: theme.spacing(2),
    paddingRight: theme.spacing(5),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(2),
  },
  subtext: {
    color: Color.ASH,
    marginTop: theme.spacing(0.5),
  },
  bold: {
    fontWeight: theme.typography.fontWeightBold,
  },
  logo: {
    backgroundColor: Color.WHITE,
    borderRadius: 28,
    boxShadow: Shadow.DEPTH_00,
    height: 40,
    overflow: 'hidden',
    position: 'relative',
    width: 40,
  },
}));

const ActionItem: React.FC<Props> = ({ onClose, transaction }) => {
  const Router = useRouter();

  const classes = useStyles();

  const firstBid = orderBy(transaction.bids, 'insertedAt')[0];
  const bid = transaction.bids.find(
    (b) => b.detailStatus === 'pending confirmation',
  );

  const calculatedType = (type: RaiseType | null) => {
    switch (type) {
      case 'entitlement':
        return 'entitlement offer';
      case 'placement':
        return 'placement';
      case 'spp':
        return 'share purchase plan';
      default:
        return 'capital raise';
    }
  };

  const navigateTo = (toHref: UrlObject | string, as?: UrlObject | string) => {
    if (onClose) {
      onClose();
    }

    Router.push(toHref, as);
  };

  const renderDefault = () => (
    <Grid
      container
      alignItems="center"
      spacing={2}
      wrap="nowrap"
      onClick={() => {
        navigateTo(routes.bidReceipt.href(transaction.raiseOffer.offerHashId));
        // window.analytics?.track(EVENTS.OR_ACTION_CENTRE_ITEM_PRESSED);
      }}
    >
      <Grid item>
        <div className={classes.logo}>
          <Image
            alt=""
            layout="fill"
            objectFit="cover"
            src={
              transaction.organisation.logoThumbUrl ??
              '/images/assets/logo-placeholder.svg'
            }
          />
        </div>
      </Grid>
      <Grid item>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="body-sm">
              {'There are some actions required on '}
              <span className={classes.bold}>
                {`${transaction.organisation.ticker} ${calculatedType(
                  transaction.raiseOffer.type,
                )}.`}
              </span>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );

  const renderConfirmBid = () => {
    if (bid) {
      return (
        <Grid
          container
          alignItems="center"
          spacing={2}
          wrap="nowrap"
          onClick={() => {
            navigateTo(routes.confirmBid.href(bid.id));
            // window.analytics?.track(EVENTS.OR_ACTION_CENTRE_ITEM_PRESSED);
          }}
        >
          <Grid item>
            <div className={classes.logo}>
              <Image
                alt=""
                layout="fill"
                objectFit="cover"
                src={
                  transaction.organisation.logoThumbUrl ??
                  '/images/assets/logo-placeholder.svg'
                }
              />
            </div>
          </Grid>
          <Grid item>
            <Grid container>
              <Grid item xs={12}>
                <Typography variant="body-sm">
                  {'Confirm your bid on '}
                  <span className={classes.bold}>
                    {`${transaction.organisation.ticker} ${calculatedType(
                      transaction.raiseOffer.type,
                    )}.`}
                  </span>
                </Typography>
              </Grid>
              {firstBid?.book?.closedAt && (
                <Grid item xs={12}>
                  <Typography className={classes.subtext} variant="body-xs">
                    {`Bids close ${formatTimeline(firstBid.book.closedAt)}`}
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      );
    }
    return renderDefault();
  };

  const renderPendingSignature = (letterType = 'offer') => {
    const offerLetter =
      letterType === 'offer'
        ? transaction.raiseOffer.offerLetters.find(
            (ol) => !!ol.content?.acceptanceDate,
          )
        : transaction.raiseOffer.confirmationLetters.find(
            (ol) => !!ol.content?.acceptanceDate,
          );

    return (
      <Grid
        container
        alignItems="center"
        spacing={2}
        wrap="nowrap"
        onClick={() => {
          navigateTo(
            routes.bidReceipt.href(transaction.raiseOffer.offerHashId),
          );
          // window.analytics?.track(EVENTS.OR_ACTION_CENTRE_ITEM_PRESSED);
        }}
      >
        <Grid item>
          <div className={classes.logo}>
            <Image
              alt=""
              layout="fill"
              objectFit="cover"
              src={
                transaction.organisation.logoThumbUrl ??
                '/images/assets/logo-placeholder.svg'
              }
            />
          </div>
        </Grid>
        <Grid item>
          <Grid container>
            <Grid item xs={12}>
              {offerLetter?.content?.acceptanceDate ? (
                <Typography variant="body-sm">
                  {'Signature required on '}
                  <span className={classes.bold}>
                    {`${transaction.organisation.ticker} ${calculatedType(
                      transaction.raiseOffer.type,
                    )}`}
                  </span>
                  {` ${letterType} letter.`}
                </Typography>
              ) : (
                <Typography variant="body-sm">
                  {'Pending signature soon on '}
                  <span className={classes.bold}>
                    {`${transaction.organisation.ticker} ${calculatedType(
                      transaction.raiseOffer.type,
                    )}`}
                  </span>
                  {` ${letterType} letter.`}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12}>
              <Typography className={classes.subtext} variant="body-xs">
                {offerLetter?.content?.acceptanceDate
                  ? `Signature due ${offerLetter.content.acceptanceDate}`
                  : `We are currently processing your ${letterType} letter(s). You will receive an email invite to sign shortly.`}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const renderSelectEntity = () => {
    const confirmationLetterMissingSignature =
      transaction.raiseOffer.confirmationLetters.length > 0 &&
      transaction.raiseOffer.confirmationLetters.some(
        (cl) => cl.sentAt !== null && cl.signedAt === null,
      );

    if (confirmationLetterMissingSignature) {
      return renderPendingSignature('confirmation');
    }

    return (
      <Grid
        container
        alignItems="center"
        spacing={2}
        wrap="nowrap"
        onClick={() => {
          navigateTo(
            routes.bidReceipt.href(transaction.raiseOffer.offerHashId),
          );
          // window.analytics?.track(EVENTS.OR_ACTION_CENTRE_ITEM_PRESSED);
        }}
      >
        <Grid item>
          <div className={classes.logo}>
            <Image
              alt=""
              layout="fill"
              objectFit="cover"
              src={
                transaction.organisation.logoThumbUrl ??
                '/images/assets/logo-placeholder.svg'
              }
            />
          </div>
        </Grid>
        <Grid item>
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="body-sm">
                {'Please select a bidding entity on '}
                <span className={classes.bold}>
                  {`${transaction.organisation.ticker} ${calculatedType(
                    transaction.raiseOffer.type,
                  )}.`}
                </span>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const renderAction = () => {
    switch (transaction.bidStatus) {
      case 'pending confirmation':
        return renderConfirmBid();
      case 'pending signature':
        return renderPendingSignature();
      case 'pending allocation':
        return renderSelectEntity();
      default:
        return renderDefault();
    }
  };

  return <div className={classes.root}>{renderAction()}</div>;
};

export default ActionItem;
