import { gql } from '@apollo/client';

export interface OrganisationGics {
  id: string;
  insertedAt: string;
  updatedAt: string;

  industryGroup: string;
  industryGroupNo: string;
  industry: string;
  industryNo: string;
  sector: string;
  sectorNo: string;
  sectorSimplified: string;
  sectorSlug: string;
  subIndustry: string;
  subIndustryNo: string;
  subIndustrySlug: string;
}

export default gql`
  fragment OrganisationGicsFragment on OrganisationGics {
    id
    insertedAt
    updatedAt

    industryGroup
    industryGroupNo
    industry
    industryNo
    sector
    sectorNo
    sectorSimplified
    sectorSlug
    subIndustry
    subIndustryNo
    subIndustrySlug
  }
`;
