// Modules
import Image from 'next/image';
// FUI
import Typography from '../../../../fui/v2/Typography';
// Images
import golfer from '../../../../public/images/assets/golfer.svg';

const NotificationEmpty: React.ComponentType = () => (
  <div className="py-6 px-10 space-y-8 bg-grey sm:py-10">
    <div className="relative h-40">
      <Image alt="" layout="fill" objectFit="contain" src={golfer} />
    </div>
    <div className="space-y-2">
      <div>
        <Typography align="center" className="text-stone" variant="heading-sm">
          You&#39;re up to date
        </Typography>
      </div>
      <div>
        <Typography align="center" className="text-stone" variant="body-lg">
          Nice one!
        </Typography>
      </div>
      <div>
        <Typography align="center" className="text-stone" variant="body-lg">
          There are no items requiring action right now.
        </Typography>
      </div>
    </div>
  </div>
);

export default NotificationEmpty;
