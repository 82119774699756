import React from 'react';
// Modules
import Link from 'next/link';
import Router from 'next/router';
import { UrlObject } from 'url';
// MUI Icons
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
// FUI
import Button from './Button';

export interface Props {
  as?: UrlObject | string;
  button?: boolean;
  href: UrlObject | string;
  onClick?: () => void;
  rel?: string;
  reverse?: boolean;
  size?: 'small' | 'medium';
  target?: string;
  text: string;
  variant?: 'external' | 'internal';
}

const ArrowLink: React.FC<Props> = ({
  as,
  button,
  href,
  onClick: onClickProps,
  rel,
  reverse,
  size,
  target,
  text,
  variant,
}) => {
  function onClick(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    if (button) {
      event.preventDefault();

      Router.push(href, as);
    }

    if (onClickProps) {
      onClickProps();
    }
  }

  function renderButton() {
    return (
      <Button
        className="group"
        color="secondary"
        endIcon={
          !reverse ? (
            <ArrowForwardIcon className="group-hover:transition-all ease-in-out delay-200 group-hover:translate-x-1/4" />
          ) : undefined
        }
        onClick={onClick}
        size={size}
        startIcon={
          reverse ? (
            <ArrowBackIcon className="group-hover:transition-all ease-in-out delay-200 group-hover:-translate-x-1/4" />
          ) : undefined
        }
        variant="text"
      >
        {text}
      </Button>
    );
  }

  if (typeof href === 'string' && variant === 'external') {
    return (
      <a href={href} rel={rel} target={target}>
        {renderButton()}
      </a>
    );
  }

  if (button) {
    return renderButton();
  }

  return (
    <Link as={as} href={href}>
      <a>{renderButton()}</a>
    </Link>
  );
};

export default ArrowLink;
