import { gql } from '@apollo/client';

export interface ResendConfirmUserMutationData {
  resendConfirmUser: {
    id: string;
  };
}

export interface ResendConfirmUserMutationVariables {
  redirect: string;
}

export default gql`
  mutation ResendConfirmUserMutation($redirect: String!) {
    resendConfirmUser(redirect: $redirect) {
      id
    }
  }
`;
