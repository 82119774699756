import React, { FC, useEffect, useRef, useState } from 'react';
// Modules
import { useApolloClient } from '@apollo/client';
import * as Sentry from '@sentry/nextjs';
import debounce from 'lodash/debounce';
// MUI Core
import AppBar from '@material-ui/core/AppBar';
import Dialog from '@material-ui/core/Dialog';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Slide from '@material-ui/core/Slide';
import TextField from '@material-ui/core/TextField';
import Toolbar from '@material-ui/core/Toolbar';
import { makeStyles } from '@material-ui/core/styles';
import { TransitionProps } from '@material-ui/core/transitions';
// MUI Icons
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';
// Components
import CompanyCardLite, {
  CompanyCardLiteSkeleton,
} from '../../../company-card/CompanyCardLite';
// FUI
import Typography from '../../../../fui/v2/Typography';
// GraphQL
import OrganisationListQuery, {
  OrganisationListItem,
  OrganisationListQueryData,
  OrganisationListQueryVariables,
} from '../../../../graphql-ts/queries/OrganisationListQuery.graphql';
// Utils
// import { EVENTS } from '../../../../utils-ts/analytics';

interface MobileSearchDialogProps {
  closeMenu?: () => void;
  onClose(): void;
  open: boolean;
}

const useStyles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: theme.palette.background.default,
    borderBottom: `1px solid ${theme.palette.divider}`,
    boxShadow: 'none',
    color: theme.palette.text.primary,
  },
  container: {
    padding: theme.spacing(2),
  },
  input: {
    padding: '6px 0px',
  },
}));

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const MobileSearchDialog: FC<MobileSearchDialogProps> = ({
  closeMenu,
  onClose,
  open,
}) => {
  const debouncedSearch = useRef(debounce(onSearch, 500));

  const client = useApolloClient();

  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [suggestions, setSuggestions] = useState<OrganisationListItem[]>([]);
  const [value, setValue] = useState('');

  useEffect(() => {
    debouncedSearch.current(value);
  }, [value]);

  const classes = useStyles();

  const onCloseModals = () => {
    onClose();
    if (closeMenu) {
      closeMenu();
    }
  };

  async function onSearch(search: string) {
    if (!search) {
      setSearchValue(search);
      setSuggestions([]);

      return;
    }

    setLoading(true);

    // window.analytics?.track(EVENTS.COMPANY_SEARCHED, {
    //   eventOrigin: 'MobileSearchDialog',
    //   value: search,
    // });

    try {
      const result = await client.query<
        OrganisationListQueryData,
        OrganisationListQueryVariables
      >({
        query: OrganisationListQuery,
        variables: {
          first: 100,
          options: {
            filters: [
              { key: 'invalidated', value: 'false' },
              { key: 'listed', value: 'true' },
              { key: 'search_full_text', value: `%${search}%` },
            ],
          },
        },
      });

      setSuggestions(
        result.data.organisationList.edges.map(({ node }) => node),
      );
    } catch (error) {
      setSuggestions([]);

      Sentry.captureException(error);
    }

    setLoading(false);
    setSearchValue(search);
  }

  function renderList() {
    if (loading) {
      return [0, 1, 2, 3].map((item) => (
        <Grid key={item} item xs={12}>
          <CompanyCardLiteSkeleton />
        </Grid>
      ));
    }

    if (suggestions.length === 0 && searchValue) {
      return (
        <Grid item xs={12}>
          <Typography variant="body-lg">
            Sorry, we can&#39;t find the company you&#39;re looking for.
          </Typography>
        </Grid>
      );
    }

    return suggestions.map((organisation) => (
      <Grid key={organisation.id} item xs={12}>
        <div onClick={onCloseModals} role="button" tabIndex={0}>
          <CompanyCardLite organisation={organisation} search={value} />
        </div>
      </Grid>
    ));
  }

  return (
    <Dialog
      fullScreen
      onClose={onCloseModals}
      open={open}
      TransitionComponent={Transition}
    >
      <AppBar className={classes.appBar}>
        <Toolbar>
          <TextField
            InputProps={{
              classes: {
                input: classes.input,
              },
              disableUnderline: true,
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton edge="end" onClick={onCloseModals}>
                    <CloseIcon />
                  </IconButton>
                </InputAdornment>
              ),
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon color="action" />
                </InputAdornment>
              ),
            }}
            autoFocus
            fullWidth
            onChange={(e) => setValue(e.target.value)}
            placeholder="Company / ASX Code"
            value={value}
          />
        </Toolbar>
      </AppBar>

      <Toolbar />

      <div className={classes.container}>
        <Grid container spacing={2}>
          {renderList()}
        </Grid>
      </div>
    </Dialog>
  );
};

export default MobileSearchDialog;
