import React, { useState } from 'react';
// Modules
import groupBy from 'lodash/groupBy';
import { useQuery } from '@apollo/client';
// MUI core
import Badge from '@material-ui/core/Badge';
import { makeStyles } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import NotificationsIcon from '@material-ui/icons/Notifications';
import Popover from '@material-ui/core/Popover';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
// FUI
import Typography from '../../../../fui/v2/Typography';
// Components
import ActionRequiredContainer from './ActionRequiredContainer';
// GraphQL
import OngoingTransactionsQuery, {
  OngoingTransactionsQueryData,
  OngoingTransactionsQueryVariables,
} from '../../../../graphql-ts/queries/OngoingTransactionsQuery.graphql';
// Utils
// import { EVENTS } from '../../../../utils-ts/analytics';
import { Color } from '../../../../utils-ts/theme';

const useStyles = makeStyles({
  actionRequired: {
    borderBottom: `1px solid ${Color.SILVER}`,
  },
  dropdown: {
    backgroundColor: Color.WHITE,
    top: 48,
    width: 368,
    zIndex: 1,
  },
});

// TODO: Make notifications update live?
// TODO: Weird bug - the whole header shifts to the left when you press the bell
const NotificationBell: React.FC = () => {
  const ongoingTransactions = useQuery<
    OngoingTransactionsQueryData,
    OngoingTransactionsQueryVariables
  >(OngoingTransactionsQuery, {
    fetchPolicy: 'cache-and-network',
  });

  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    // window.analytics?.track(EVENTS.OR_ACTION_CENTRE_PRESSED);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const notificationsOpen = Boolean(anchorEl);

  const groupedTransactions = groupBy(
    ongoingTransactions.data?.ongoingTransactions ?? [],
    'actionRequired',
  );

  const actionRequiredTransactions = groupedTransactions.true ?? [];

  return (
    <div>
      <IconButton onClick={handleClick}>
        <Badge
          color="secondary"
          variant="dot"
          invisible={actionRequiredTransactions.length === 0}
        >
          <NotificationsIcon />
        </Badge>
      </IconButton>
      <Popover
        open={notificationsOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 48,
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <div className={classes.dropdown}>
          <Grid container>
            <Grid item xs={12}>
              <Box p={2} className={classes.actionRequired}>
                <Typography variant="heading-sm">Action required</Typography>
              </Box>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <ActionRequiredContainer
              isModal={false}
              onClose={() => handleClose()}
              transactions={actionRequiredTransactions}
            />
          </Grid>
        </div>
      </Popover>
    </div>
  );
};

export default NotificationBell;
