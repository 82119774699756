import { gql } from '@apollo/client';
// Utils
import { RaiseType } from '../../utils-ts/interfaces';
import { BidDetailStatus, BidStatus } from '../../utils-ts/types';

export interface Transaction {
  actionRequired: boolean;
  allocatedDollars: number;
  allocatedOptions: number | null;
  allocatedShares: number | null;
  bidStatus: BidStatus;
  bids: {
    id: string;
    insertedAt: string;
    allocatedAt: string;
    bid: number;
    biddingEntity: {
      id: string;
    } | null;
    book: {
      id: string;
      closedAt: string;
    } | null;
    confirmedAt: string | null;
    options: number | null;
    reviewed: boolean;
    shares: number | null;
    detailStatus: BidDetailStatus;
  }[];
  hasPendingBidIncreaseRequest: boolean;
  organisation: {
    id: string;
    logoThumbUrl: string | null;
    name: string;
    ticker: string | null;
  };
  raiseOffer: {
    id: string;
    offerHashId: string;
    type: RaiseType | null;
    offerLetters: {
      id: string;
      content: {
        acceptanceDate: string | null;
      } | null;
    }[];
    confirmationLetters: {
      id: string;
      content: {
        acceptanceDate: string | null;
      } | null;
      sentAt: string | null;
      signedAt: string | null;
    }[];
  };
}

export interface OngoingTransactionsQueryData {
  ongoingTransactions: Transaction[] | null;
}

export interface OngoingTransactionsQueryVariables {}

const OngoingTransactionsQuery = gql`
  query OngoingTransactionsQuery {
    ongoingTransactions {
      actionRequired
      allocatedDollars
      allocatedOptions
      allocatedShares
      bidStatus
      bids {
        id
        insertedAt
        allocatedAt
        bid
        biddingEntity {
          id
        }
        book {
          id
          closedAt
        }
        confirmedAt
        options
        reviewed
        shares
        detailStatus
      }
      hasPendingBidIncreaseRequest
      organisation {
        id
        logoThumbUrl
        name
        ticker
      }
      raiseOffer {
        id
        offerLetters {
          id
          content {
            acceptanceDate
          }
        }
        confirmationLetters {
          id
          content {
            acceptanceDate
          }
          sentAt
          signedAt
        }
        offerHashId
        type
      }
    }
  }
`;

export default OngoingTransactionsQuery;
