// MUI Core
import Badge from '@material-ui/core/Badge';
import { withStyles } from '@material-ui/core/styles';
import { Color } from '../../utils-ts/theme';

const NewFeatureNotifier = withStyles((theme) => ({
  badge: {
    backgroundColor: Color.FRESH,
    borderRadius: 6,
    boxShadow: `0 0 0 3px ${Color.FRESH}80`,
    color: Color.FRESH,
    height: 12,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(2),
    minWidth: 12,
    position: 'relative',
    right: 0,
    top: 0,
    transform: 'none',
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: '$ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 0.8,
    },
    '100%': {
      transform: 'scale(2.2)',
      opacity: 0,
    },
  },
}))(Badge);

const NewFeatureNotifierSmall = withStyles((theme) => ({
  badge: {
    backgroundColor: theme.palette.secondary.main,
    borderRadius: 6,
    boxShadow: `0 0 0 3px ${Color.FRESH}80`,
    color: theme.palette.secondary.main,
    height: 7,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(2),
    minWidth: 7,
    position: 'relative',
    right: 0,
    top: 0,
    transform: 'none',
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: '$ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 0.8,
    },
    '100%': {
      transform: 'scale(2.2)',
      opacity: 0,
    },
  },
}))(Badge);

export { NewFeatureNotifier, NewFeatureNotifierSmall };
