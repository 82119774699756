import React from 'react';
// Modules
import { useMutation } from '@apollo/client';
import dayjs from 'dayjs';
import Link from 'next/link';
// Components
import { useFeatureOnboarding } from '../../hooks/FeatureOnboardingHooks';
// import useOfficeClosure from '../../hooks/useOfficeClosure';
// Contexts
import SnackbarContext from '../../../contexts/SnackbarContext';
// FUI
import Button from '../../../fui/v2/Button';
import Typography from '../../../fui/v2/Typography';
// GraphQL
import ResendConfirmUserMutation, {
  ResendConfirmUserMutationData,
  ResendConfirmUserMutationVariables,
} from '../../../graphql-ts/mutations/ResendConfirmUserMutation.graphql';
import { Me } from '../../../graphql-ts/queries/MeQuery.graphql';
// Utils
import { StorageKey } from '../../../utils-ts/constants';
import { getRedirectURL } from '../../../utils-ts/helper';
import { href } from '../../../utils-ts/pages';

interface Props {
  me: Me;
  displayCTA?: boolean;
}

const OnboardingReminderBar: React.FC<Props> = ({ me, displayCTA }) => {
  const { markFeatureOnboarding, show } =
    useFeatureOnboarding('notification_v2_sw');

  // Re-enable this for holidays
  // const { isOfficeClosed } = useOfficeClosure();

  const [resendConfirmUser, { loading: isSendingEmail }] = useMutation<
    ResendConfirmUserMutationData,
    ResendConfirmUserMutationVariables
  >(ResendConfirmUserMutation);

  const { showSnackbar } = React.useContext(SnackbarContext);

  const [hidePendingBar, setHidePendingBar] = React.useState(false);

  React.useEffect(() => {
    const hidden = localStorage.getItem(StorageKey.HIDE_ONBOARDING_PENDING_BAR);

    setHidePendingBar(hidden === 'hidden');
  }, []);

  const limitedRole = me.expandedRole.find(
    (er) => er.name === 'Limited Investor',
  );

  const unfinishedTask = me.onboardingStatus.filter(
    (os) =>
      ['email_confirm', 'mobile_confirm', 'iv_check', 's708'].includes(
        os.key,
      ) && os.status === 'not_exist',
  );

  function dismissPendingBar(
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) {
    event.stopPropagation();
    localStorage.setItem(StorageKey.HIDE_ONBOARDING_PENDING_BAR, 'hidden');
    setHidePendingBar(true);
  }

  async function onClickResend() {
    try {
      await resendConfirmUser({
        variables: {
          redirect: getRedirectURL(href.emailConfirmed),
        },
      });

      showSnackbar(`Confirmation email re-sent to: ${me.email}`, 'success');
    } catch (error) {
      showSnackbar('Sorry, we were unable to resend the email.', 'error');
    }
  }

  if (
    me.expandedRole.some((er) =>
      ['Qualified Investor', 'Retail Investor'].includes(er.name),
    )
  ) {
    if (show) {
      return (
        <div className="flex relative justify-center items-center p-4 text-white bg-premium">
          <div className="flex justify-center items-center space-x-4">
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-6 h-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M11 5.882V19.24a1.76 1.76 0 01-3.417.592l-2.147-6.15M18 13a3 3 0 100-6M5.436 13.683A4.001 4.001 0 017 6h1.832c4.1 0 7.625-1.234 9.168-3v14c-1.543-1.766-5.067-3-9.168-3H7a3.988 3.988 0 01-1.564-.317z"
                />
              </svg>
            </div>
            <div>
              <Typography variant="button">
                <Link href={href.profileNotifications}>
                  <a className="text-white">
                    Customise your notification preferences
                  </a>
                </Link>
              </Typography>
            </div>
          </div>
          <button
            className="hidden absolute top-1/2 right-4 -translate-y-1/2 sm:block"
            onClick={() => markFeatureOnboarding()}
            type="button"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="w-5 h-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>
      );
    }

    // if (isOfficeClosed) {
    //   return (
    //     <div className="flex relative justify-center items-center p-4 text-white bg-premium">
    //       <div className="flex justify-center items-center space-x-4">
    //         <div>
    //           <svg
    //             xmlns="http://www.w3.org/2000/svg"
    //             className="w-6 h-6"
    //             fill="none"
    //             viewBox="0 0 24 24"
    //             stroke="currentColor"
    //           >
    //             <path
    //               strokeLinecap="round"
    //               strokeLinejoin="round"
    //               strokeWidth={2}
    //               d="M11 5.882V19.24a1.76 1.76 0 01-3.417.592l-2.147-6.15M18 13a3 3 0 100-6M5.436 13.683A4.001 4.001 0 017 6h1.832c4.1 0 7.625-1.234 9.168-3v14c-1.543-1.766-5.067-3-9.168-3H7a3.988 3.988 0 01-1.564-.317z"
    //             />
    //           </svg>
    //         </div>
    //         <div>
    //           <Typography variant="button">
    //             Our office is closed on the 14th of March 2022. We will be back
    //             online on Tuesday, the 15th of March 2022.
    //           </Typography>
    //         </div>
    //       </div>
    //     </div>
    //   );
    // }

    return null;
  }

  if (limitedRole) {
    const timeLeft = limitedRole.expiredAt
      ? dayjs(limitedRole.expiredAt).diff(dayjs(), 'hours')
      : 72;

    const renderButton = () => {
      if (
        me.onboardingStatus.some(
          (os) => os.key === 's708' && os.status === 'not_exist',
        )
      ) {
        return (
          <Link href={href.verifyInvestorQualification}>
            <a>
              <Button color="secondary" size="small">
                Verify your s708/AFSL now
              </Button>
            </a>
          </Link>
        );
      }

      return null;
    };

    return (
      <div className="flex relative justify-center items-center p-4 text-white bg-premium">
        <div className="flex justify-center items-center space-x-4">
          <div>
            <Typography variant="button">
              {timeLeft > 96
                ? 'You have limited access to offer terms.'
                : `You have access to offer terms for the next ${timeLeft} hours`}
            </Typography>
          </div>
          {displayCTA && <div>{renderButton()}</div>}
        </div>
      </div>
    );
  }

  if (unfinishedTask.length > 1) {
    return (
      <div className="flex relative justify-center items-center p-4 text-white bg-premium">
        <div className="flex justify-center items-center space-x-4">
          <div className="flex">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="w-5 h-5"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                clipRule="evenodd"
              />
            </svg>
          </div>
          <div>
            <Typography variant="button">
              Unlock offer terms and bidding
            </Typography>
          </div>
          {displayCTA && (
            <div>
              <Link href={href.profile}>
                <a>
                  <Button color="secondary" size="small">
                    Finish account setup
                  </Button>
                </a>
              </Link>
            </div>
          )}
        </div>
      </div>
    );
  }

  if (
    me.onboardingStatus.some(
      (os) => os.key === 'email_confirm' && os.status === 'not_exist',
    )
  ) {
    return (
      <div className="flex relative justify-center items-center p-4 text-white bg-premium">
        <div className="flex justify-center items-center space-x-4">
          <div>
            <Typography variant="button">
              To bid, please confirm your email address
            </Typography>
          </div>
          {displayCTA && (
            <div>
              <Button
                color="secondary"
                disabled={isSendingEmail}
                onClick={onClickResend}
                size="small"
              >
                Re-send email
              </Button>
            </div>
          )}
        </div>
      </div>
    );
  }

  if (
    me.onboardingStatus.some(
      (os) => os.key === 'mobile_confirm' && os.status === 'not_exist',
    )
  ) {
    return (
      <div className="flex relative justify-center items-center p-4 text-white bg-premium">
        <div className="flex justify-center items-center space-x-4">
          <div>
            <Typography variant="button">
              To bid, please confirm your mobile number
            </Typography>
          </div>
          {displayCTA && (
            <div>
              <Link href={href.profileSetMobileNumber}>
                <a>
                  <Button color="secondary" size="small">
                    Confirm mobile
                  </Button>
                </a>
              </Link>
            </div>
          )}
        </div>
      </div>
    );
  }

  if (
    me.onboardingStatus.some(
      (os) => os.key === 'iv_check' && os.status === 'not_exist',
    )
  ) {
    return (
      <div className="flex relative justify-center items-center p-4 text-white bg-premium">
        <div className="flex justify-center items-center space-x-4">
          <div>
            <Typography variant="button">
              To bid, please confirm your identity
            </Typography>
          </div>
          {displayCTA && (
            <div>
              <Link href={href.verifyIdentity}>
                <a>
                  <Button color="secondary" size="small">
                    Confirm identity
                  </Button>
                </a>
              </Link>
            </div>
          )}
        </div>
      </div>
    );
  }

  if (
    me.onboardingStatus.some(
      (os) => os.key === 's708' && os.status === 'not_exist',
    )
  ) {
    return (
      <div className="flex relative justify-center items-center p-4 text-white bg-premium">
        <div className="flex justify-center items-center space-x-4">
          <div>
            <Typography variant="button">
              To unlock offer terms and bidding, verify your s708/AFSL now
            </Typography>
          </div>
          {displayCTA && (
            <div>
              <Link href={href.verifyInvestorQualification}>
                <a>
                  <Button color="secondary" size="small">
                    Verify your s708/AFSL now
                  </Button>
                </a>
              </Link>
            </div>
          )}
        </div>
      </div>
    );
  }

  if (
    me.onboardingStatus.some(
      (os) => ['iv_check', 's708'].includes(os.key) && os.status === 'pending',
    ) &&
    !hidePendingBar
  ) {
    return (
      <div className="flex relative justify-center items-center p-4 text-stone bg-grey">
        <div
          className="flex justify-center items-center space-x-4 outline-none cursor-pointer"
          onClick={() => window.Intercom && window.Intercom('show')}
          role="button"
          tabIndex={0}
        >
          <div>
            <Typography variant="button">
              <span className="font-normal">
                Your account is being reviewed, no action required | Need help?
              </span>
              &nbsp;Contact Us
            </Typography>
          </div>
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="w-5 h-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M9 5l7 7-7 7"
              />
            </svg>
          </div>
        </div>
        <button
          className="hidden absolute top-1/2 right-4 -translate-y-1/2 sm:block"
          onClick={dismissPendingBar}
          type="button"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="w-5 h-5"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
      </div>
    );
  }

  return null;
};

export default OnboardingReminderBar;
