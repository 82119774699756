import React, { FC } from 'react';
// Modules
import { useQuery } from '@apollo/client';
import clsx from 'clsx';
// Components
import OnboardingReminderBar from '../onboarding-new/reminder-bar/OnboardingReminderBar';
import NavBar from './navbar/NavBar';
import OnboardingNavBar from './navbar/OnboardingNavBar';
import KYCNavBar from './navbar/KYCNavBar';
import Footer from './Footer';
// GraphQL
import MeQuery, { MeQueryData } from '../../graphql-ts/queries/MeQuery.graphql';

type RootVariant =
  | 'auth'
  | 'default'
  | 'home'
  | 'kyc-check'
  | 'offer-letter'
  | 'onboarding'
  | 'relative';

type BackgroundVariant = 'default' | 'grey';

interface RootProps {
  backgroundVariant?: BackgroundVariant;
  hiddenOnboardingReminderCTA?: boolean;
  hideFooter?: boolean;
  hideOnboardingReminder?: boolean;
  showWallCrossedBanner?: boolean;
  variant?: RootVariant;
}

const Root: FC<RootProps> = ({
  backgroundVariant,
  hiddenOnboardingReminderCTA,
  children,
  hideFooter,
  hideOnboardingReminder,
  showWallCrossedBanner,
  variant,
}) => {
  const { data } = useQuery<MeQueryData>(MeQuery);

  function renderOnboardingBar() {
    if (data?.me && !hideOnboardingReminder) {
      return (
        <OnboardingReminderBar
          me={data.me}
          displayCTA={!hiddenOnboardingReminderCTA}
        />
      );
    }

    return null;
  }

  switch (variant) {
    case 'auth':
      return (
        <div className="flex flex-col h-full">
          <div
            className={clsx(
              'flex-1 mt-20',
              backgroundVariant === 'grey' && 'bg-grey-subdued',
            )}
          >
            {children}
          </div>
        </div>
      );

    case 'home':
      return <div className="relative h-full">{children}</div>;

    case 'offer-letter':
      return (
        <div className="flex flex-col h-full">
          <NavBar variant="offer-letter" />
          <div
            className={clsx(
              'flex-1 mt-20',
              backgroundVariant === 'grey' && 'bg-grey-subdued',
            )}
          >
            {children}
          </div>
        </div>
      );

    case 'onboarding':
      return (
        <div className="flex flex-col h-full">
          <OnboardingNavBar />
          <div
            className={clsx(
              'flex-1 mt-20',
              backgroundVariant === 'grey' && 'bg-grey-subdued',
            )}
          >
            {children}
          </div>
        </div>
      );

    case 'kyc-check':
      return (
        <div className="flex flex-col h-full">
          <KYCNavBar />
          <div
            className={clsx(
              'flex-1 mt-20',
              backgroundVariant === 'grey' && 'bg-grey-subdued',
            )}
          >
            {children}
          </div>
        </div>
      );

    default:
      return (
        <div className="flex flex-col h-full">
          <NavBar showWallCrossedBanner={showWallCrossedBanner} />
          <div
            className={clsx(
              'flex-1 mt-20',
              backgroundVariant === 'grey' && 'bg-grey-subdued',
              variant === 'relative' && 'relative',
            )}
          >
            {renderOnboardingBar()}
            {children}
          </div>
          {!hideFooter && <Footer />}
        </div>
      );
  }
};

export default Root;
