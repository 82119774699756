import React, { FC, useRef } from 'react';
// Modules
import clsx from 'clsx';
import { useQuery } from '@apollo/client';
import Link from 'next/link';
// MUI Core
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
// Components
import Learn from './mega-menu/SectionLearn';
import ContactUs from './mega-menu/SectionContactUs';
import Raises from './mega-menu/SectionRaises';
import Research from './mega-menu/SectionResearch';
// Contexts
import { useWebView } from '../../../contexts/WebViewContext';
// GraphQL
import MeQuery, {
  MeQueryData,
} from '../../../graphql-ts/queries/MeQuery.graphql';
// Utils
import { Color } from '../../../utils-ts/theme';
import { asPath, href } from '../../../utils-ts/pages';

interface Props {
  hideMenu?: boolean;
}

const useStyles = makeStyles((theme) => ({
  section: {
    position: 'relative',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  flex1: {
    [theme.breakpoints.up('md')]: {
      flex: 1,
    },
  },
  hiddenMdUp: {
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  hiddenSmDown: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  link: {
    color: theme.palette.secondary.dark,
  },
  logo: {
    height: 24,
    marginRight: theme.spacing(3),
    width: 'auto',
    [theme.breakpoints.up('md')]: {
      height: 30,
    },
  },
  megaMenuBg: {
    position: 'fixed',
    left: 0,
    right: 0,
    top: 126,
    width: '100%',
    backgroundColor: Color.GREY,
    height: 328,
    minHeight: 328,
    opacity: 0,
    pointerEvents: 'none',
    cursor: 'auto',
    transform: 'translateY(-15px)',
    transition: 'all .1s ease-in',
    zIndex: theme.zIndex.appBar - 2,
    boxShadow: '0px 16px 16px -8px rgba(0,0,0,.1)',
  },
  megaMenuTrigger: {
    '&:hover > $megaMenuBg': {
      opacity: 1,
      transform: 'translateY(0px)',
      transition: 'all .15s ease-out',
    },
  },
}));

const NavBarContent: FC<Props> = ({ hideMenu }) => {
  const { data } = useQuery<MeQueryData>(MeQuery);

  const logoRef = useRef<HTMLDivElement>(null);

  const megaMenuRef = useRef<HTMLDivElement>(null);

  const classes = useStyles();

  const { isWebView } = useWebView();

  const homePath = data && data.me ? asPath.capitalRaises(true) : href.home;

  return (
    <Grid item xs={6} md="auto">
      <Grid container wrap="nowrap">
        {/* Logo */}
        <Grid item ref={logoRef}>
          {isWebView ? (
            <div className={classes.section}>
              {/* eslint-disable-next-line @next/next/no-img-element */}
              <img
                className={classes.logo}
                alt="Fresh Equities"
                src="/images/assets/logo-horizontal-green.svg"
                width="215"
                height="32"
              />
            </div>
          ) : (
            <Link href={homePath}>
              <a className={classes.section}>
                {/* eslint-disable-next-line @next/next/no-img-element */}
                <img
                  className={classes.logo}
                  alt="Fresh Equities"
                  src="/images/assets/logo-horizontal-green.svg"
                  width="215"
                  height="32"
                />
              </a>
            </Link>
          )}
        </Grid>

        {!hideMenu && (
          <>
            <Grid
              item
              className={clsx(classes.megaMenuTrigger, classes.hiddenSmDown)}
              ref={megaMenuRef}
            >
              <Grid container wrap="nowrap">
                {/* Raises */}
                <Grid item>
                  <Raises />
                </Grid>

                {/* Research */}
                <Grid item>
                  <Research />
                </Grid>
              </Grid>
              <div className={classes.megaMenuBg} />
            </Grid>

            <Grid
              item
              className={clsx(classes.megaMenuTrigger, classes.hiddenSmDown)}
            >
              <Grid container wrap="nowrap">
                {/* Learn */}
                <Grid item>
                  <Learn logoRef={logoRef} />
                </Grid>
              </Grid>

              <div className={classes.megaMenuBg} />
            </Grid>

            <Grid
              item
              className={clsx(classes.megaMenuTrigger, classes.hiddenSmDown)}
            >
              <Grid container wrap="nowrap">
                {/* Contact Us */}

                <Grid item>
                  <ContactUs />
                </Grid>
              </Grid>
            </Grid>
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default NavBarContent;
