import React, { FC } from 'react';
// Modules
import Link from 'next/link';
import { UrlObject } from 'url';
// MUI Core
import { makeStyles } from '@material-ui/core/styles';
// Components
import { NewFeatureNotifierSmall } from '../../../common/NewFeatureNotifier';
// Utils
import { Color } from '../../../../utils-ts/theme';

interface MegaMenuSectionCommonProps {
  newFeature?: boolean;
  onClick?: () => void;
  title?: string;
}
interface MegaMenuSectionInternalProps {
  linkAs?: string | UrlObject;
  linkHref?: string | UrlObject;
  isExternal?: false | undefined;
}

interface MegaMenuSectionExternalProps {
  linkAs?: never;
  linkHref: string;
  isExternal?: true;
}

type MegaMenuExternalOrInternalProps =
  | MegaMenuSectionInternalProps
  | MegaMenuSectionExternalProps;
type MegaMenuSectionProps = MegaMenuSectionCommonProps &
  MegaMenuExternalOrInternalProps;

const useStyles = makeStyles((theme) => ({
  content: {
    backgroundColor: Color.GREY_SUBDUED,
    position: 'absolute',
    left: 250,
    right: 0,
    height: '100%',
    padding: '30px 32px',
    pointerEvents: 'none',
    opacity: 0,
  },
  contentInner: {
    maxWidth: 720,
  },
  link: {
    position: 'absolute',
    left: 0,
    top: 0,
    borderLeft: '1px solid transparent',
    color: theme.palette.text.primary,
    cursor: 'pointer',
    display: 'inline',
    fontSize: 14,
    fontWeight: 500,
    padding: 13,
    flex: '0 1 250px',
    minWidth: 250,
    '&:hover, &:active': {},
  },
  notifier: {
    position: 'absolute',
    top: 3,
  },
  notifierWrapper: {
    position: 'relative',
  },
  section: {
    '&:nth-of-type(1)': {
      '& $link': {
        top: 15,
      },
    },
    '&:nth-of-type(2)': {
      '& $link': {
        top: 63,
      },
    },
    '&:nth-of-type(3)': {
      '& $link': {
        top: 111,
      },
    },
    '&:nth-of-type(4)': {
      '& $link': {
        top: 159,
      },
    },
    '&:nth-of-type(5)': {
      '& $link': {
        top: 207,
      },
    },
    '&:nth-of-type(6)': {
      '& $link': {
        top: 255,
      },
    },
    '&:hover, &:active': {
      backgroundColor: Color.GREY_SUBDUED,
      borderColor: theme.palette.secondary.main,
      color: theme.palette.secondary.main,
      '& $content': {
        opacity: 1,
        pointerEvents: 'all',
      },
      '& $link': {
        backgroundColor: Color.GREY_SUBDUED,
        borderColor: theme.palette.secondary.main,
        color: theme.palette.secondary.dark,
      },
    },
  },
}));

const MegaMenuSection: FC<MegaMenuSectionProps> = ({
  children,
  isExternal,
  linkAs,
  linkHref,
  newFeature,
  onClick,
  title,
}) => {
  const classes = useStyles();

  const renderLink = () => {
    if (isExternal) {
      return (
        <a
          className={classes.link}
          href={linkHref as string}
          onClick={onClick}
          rel="noopener noreferrer"
          target="_blank"
        >
          {title}
          {newFeature && (
            <span className={classes.notifierWrapper}>
              <NewFeatureNotifierSmall
                overlap="circle"
                variant="dot"
                className={classes.notifier}
              >
                {/* eslint-disable-next-line react/jsx-curly-brace-presence */}
                {''}
              </NewFeatureNotifierSmall>
            </span>
          )}
        </a>
      );
    }
    return (
      <Link href={linkHref || ''} as={linkAs || ''}>
        {/* eslint-disable-next-line */}
        <a className={classes.link} onClick={onClick}>
          {title}
          {newFeature && (
            <span className={classes.notifierWrapper}>
              <NewFeatureNotifierSmall
                overlap="circle"
                variant="dot"
                className={classes.notifier}
              >
                {/* eslint-disable-next-line react/jsx-curly-brace-presence */}
                {''}
              </NewFeatureNotifierSmall>
            </span>
          )}
        </a>
      </Link>
    );
  };

  return (
    <div className={classes.section}>
      {renderLink()}
      <div className={classes.content}>
        <div className={classes.contentInner}>{children}</div>
      </div>
    </div>
  );
};

export default MegaMenuSection;
