import React, { FC, useContext, useEffect, useState } from 'react';
// Modules
import { useQuery } from '@apollo/client';
import * as Sentry from '@sentry/nextjs';
import axios from 'axios';
import Link from 'next/link';
import Router, { useRouter } from 'next/router';
import { UrlObject } from 'url';
// MUI Core
import Avatar from '@material-ui/core/Avatar';
import Dialog from '@material-ui/core/Dialog';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Slide from '@material-ui/core/Slide';
import { makeStyles } from '@material-ui/core/styles';
import { TransitionProps } from '@material-ui/core/transitions';
// MUI Icons
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
// Components
import NotificationBellMobile from '../notifications/NotificationBellMobile';
import MobileSearchDialog from './MobileSearchDialog';
// FUI
import Button from '../../../../fui/v2/Button';
import Typography from '../../../../fui/v2/Typography';
// Contexts
import SnackbarContext from '../../../../contexts/SnackbarContext';
// GraphQL
import MeQuery, {
  MeQueryData,
} from '../../../../graphql-ts/queries/MeQuery.graphql';
// Utils
import { DynamicLandingPage } from '../../../../utils-ts/contentful/landingPages';
import { getErrorMessage } from '../../../../utils-ts/helper';
import { asPath, href } from '../../../../utils-ts/pages';
import routes from '../../../../utils-ts/routes';
import { Color } from '../../../../utils-ts/theme';

interface MobileMenuProps {
  onClose(): void;
  open: boolean;
}

type MenuType =
  | 'learn'
  | 'asx-market-data'
  | 'none'
  | 'raises'
  | 'markets'
  | 'sector-breakdowns'
  | 'use-cases';

const useStyles = makeStyles((theme) => ({
  accountMenu: {
    backgroundColor: Color.GREY_SUBDUED,
    color: Color.STONE,
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.background.default,
    fontSize: 14,
    height: 32,
    width: 32,
  },
  backButton: {
    alignItems: 'center',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.background.default,
    cursor: 'pointer',
    display: 'flex',
    height: 58,
    padding: '0 8px',
  },
  backIcon: {
    marginRight: theme.spacing(),
  },
  childListItem: {
    paddingLeft: 40,
  },
  container: {
    padding: theme.spacing(2),
  },
  flex: {
    display: 'flex',
  },
  logo: {
    height: 24,
    marginRight: theme.spacing(4),
    width: 'auto',
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  newFeatureWrap: {
    position: 'relative',
  },
  notifier: {
    position: 'absolute',
    top: 0,
    left: -24,
  },
}));

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const MobileMenu: FC<MobileMenuProps> = ({ onClose, open }) => {
  const { data } = useQuery<MeQueryData>(MeQuery);

  const router = useRouter();

  const { showSnackbar } = useContext(SnackbarContext);

  const [selectedMenu, setSelectedMenu] = useState<MenuType>('none');
  const [sectors, setSectors] = useState<DynamicLandingPage[]>([]);
  const [useCases, setUseCases] = useState<DynamicLandingPage[]>([]);
  const [isMobileSearchDialogOpen, setMobileSearchDialogOpen] = useState(false);

  const classes = useStyles();

  useEffect(() => {
    fetchSectors();
    fetchUseCases();
  }, []);

  function closeMenu() {
    onClose();

    setSelectedMenu('none');
  }

  function fetchSectors() {
    axios
      .get('/api/sectors')
      .then((res) => {
        setSectors(res.data.data.pages);
      })
      .catch(() => {
        setSectors([]);
      });
  }

  function fetchUseCases() {
    axios
      .get('/api/use-cases')
      .then((res) => {
        setUseCases(res.data.data.pages);
      })
      .catch(() => {
        setUseCases([]);
      });
  }

  function getUserInitial() {
    if (data && data.me) {
      if (data.me.firstName && data.me.lastName) {
        return `${data.me.firstName.charAt(0).toUpperCase()}${data.me.lastName
          .charAt(0)
          .toUpperCase()}`;
      }

      return data.me.email.charAt(0).toUpperCase();
    }

    return '';
  }

  async function onClickLogOut() {
    closeMenu();

    try {
      await fetch(`${process.env.NEXT_PUBLIC_AUTH_URL}/logout`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      });

      localStorage.clear();

      // window.analytics?.reset();

      window.location.assign(href.home);
    } catch (error) {
      showSnackbar(getErrorMessage(error), 'error');

      Sentry.captureException(error);
    }
  }

  function navigateTo(toHref: UrlObject | string, as?: UrlObject | string) {
    closeMenu();

    Router.push(toHref, as);
  }

  function renderLearnMenu() {
    return (
      <Slide direction="left" in>
        <div className={classes.container}>
          <div
            className={classes.backButton}
            onClick={() => setSelectedMenu('none')}
            role="button"
            tabIndex={0}
          >
            <ChevronLeftIcon className={classes.backIcon} color="inherit" />
            <Typography color="inherit" variant="button">
              Learn
            </Typography>
          </div>

          <List disablePadding>
            {/* <ListItem
              className={classes.childListItem}
              button
              divider
              onClick={() => navigateTo(href.about)}
            >
              <ListItemText
                primary={<Typography variant="button">About Us</Typography>}
              />
            </ListItem> */}

            <ListItem
              className={classes.childListItem}
              button
              divider
              onClick={() => setSelectedMenu('use-cases')}
            >
              <ListItemText
                primary={<Typography variant="button">Use Cases</Typography>}
              />
              <ChevronRightIcon />
            </ListItem>

            <ListItem
              className={classes.childListItem}
              button
              divider
              onClick={() => navigateTo(href.resourceCentre)}
            >
              <ListItemText
                primary={
                  <Typography variant="button">Resource Centre</Typography>
                }
              />
            </ListItem>

            {/* <ListItem
              className={classes.childListItem}
              button
              divider
              onClick={() => navigateTo(routes.freshImpact.href)}
            >
              <ListItemText
                primary={<Typography variant="button">Fresh Impact</Typography>}
              />
            </ListItem> */}
            <ListItem
              className={classes.childListItem}
              button
              divider
              onClick={() => navigateTo(href.faq)}
            >
              <ListItemText
                primary={<Typography variant="button">FAQs</Typography>}
              />
            </ListItem>
          </List>
        </div>
      </Slide>
    );
  }

  function renderAsxMarketDataMenu() {
    return (
      <Slide direction="left" in>
        <div className={classes.container}>
          <div
            className={classes.backButton}
            onClick={() => setSelectedMenu('markets')}
            role="button"
            tabIndex={0}
          >
            <ChevronLeftIcon className={classes.backIcon} color="inherit" />
            <Typography color="inherit" variant="button">
              ASX Market Data
            </Typography>
          </div>

          <List disablePadding>
            <ListItem
              className={classes.childListItem}
              button
              divider
              onClick={() => navigateTo(href.markets)}
            >
              <ListItemText
                primary={
                  <Typography variant="button">ASX Market Data</Typography>
                }
              />
            </ListItem>
            <ListItem
              className={classes.childListItem}
              button
              divider
              onClick={() => navigateTo(href.asx)}
            >
              <ListItemText
                primary={
                  <Typography variant="button">ASX Companies</Typography>
                }
              />
            </ListItem>

            <ListItem
              className={classes.childListItem}
              button
              divider
              onClick={() => navigateTo(href.asxSectors)}
            >
              <ListItemText
                primary={
                  <span className={classes.newFeatureWrap}>
                    <Typography variant="button">ASX Sectors</Typography>
                  </span>
                }
              />
            </ListItem>

            <ListItem
              className={classes.childListItem}
              button
              divider
              onClick={() => navigateTo(href.indices)}
            >
              <ListItemText
                primary={
                  <span className={classes.newFeatureWrap}>
                    <Typography variant="button">ASX Indices</Typography>
                  </span>
                }
              />
            </ListItem>
          </List>
        </div>
      </Slide>
    );
  }

  function renderRaisesMenu() {
    return (
      <Slide direction="left" in>
        <div className={classes.container}>
          <div
            className={classes.backButton}
            onClick={() => setSelectedMenu('none')}
            role="button"
            tabIndex={0}
          >
            <ChevronLeftIcon className={classes.backIcon} color="inherit" />
            <Typography color="inherit" variant="button">
              Raises
            </Typography>
          </div>

          <List disablePadding>
            <ListItem
              className={classes.childListItem}
              button
              divider
              onClick={() => navigateTo(href.liveCapitalRaises)}
            >
              <ListItemText
                primary={
                  <Typography variant="button">Live Capital Raises</Typography>
                }
              />
            </ListItem>

            <ListItem
              className={classes.childListItem}
              button
              divider
              onClick={() => navigateTo(href.pastCapitalRaises)}
            >
              <ListItemText
                primary={
                  <Typography variant="button">Past Capital Raises</Typography>
                }
              />
            </ListItem>
          </List>
        </div>
      </Slide>
    );
  }

  function renderMarketsMenu() {
    return (
      <Slide direction="left" in>
        <div className={classes.container}>
          <div
            className={classes.backButton}
            onClick={() => setSelectedMenu('none')}
            role="button"
            tabIndex={0}
          >
            <ChevronLeftIcon className={classes.backIcon} color="inherit" />
            <Typography color="inherit" variant="button">
              Markets
            </Typography>
          </div>

          <List disablePadding>
            <ListItem
              className={classes.childListItem}
              button
              divider
              onClick={() => setSelectedMenu('asx-market-data')}
            >
              <ListItemText
                primary={
                  <Typography variant="button">ASX Market Data</Typography>
                }
              />
              <ChevronRightIcon />
            </ListItem>

            <ListItem
              className={classes.childListItem}
              button
              divider
              onClick={() => setSelectedMenu('sector-breakdowns')}
            >
              <ListItemText
                primary={
                  <Typography variant="button">Sector Breakdowns</Typography>
                }
              />
              <ChevronRightIcon />
            </ListItem>
          </List>
        </div>
      </Slide>
    );
  }

  function renderRootLevelMenu() {
    return (
      <>
        <div className={classes.container}>
          <List disablePadding>
            <ListItem button divider onClick={() => setSelectedMenu('raises')}>
              <ListItemText
                primary={<Typography variant="button">Raises</Typography>}
              />
              <ChevronRightIcon />
            </ListItem>

            <ListItem button divider onClick={() => setSelectedMenu('markets')}>
              <ListItemText
                primary={<Typography variant="button">Markets</Typography>}
              />
              <ChevronRightIcon />
            </ListItem>

            <ListItem button divider onClick={() => setSelectedMenu('learn')}>
              <ListItemText
                primary={<Typography variant="button">Learn</Typography>}
              />
              <ChevronRightIcon />
            </ListItem>

            <ListItem button divider onClick={() => navigateTo(href.contactUs)}>
              <ListItemText
                primary={<Typography variant="button">Contact Us</Typography>}
              />
            </ListItem>
          </List>
        </div>

        <div className={classes.container}>{renderUserMenu()}</div>
      </>
    );
  }

  function renderSectorBreakdownsMenu() {
    return (
      <Slide direction="left" in>
        <div className={classes.container}>
          <div
            className={classes.backButton}
            onClick={() => setSelectedMenu('markets')}
            role="button"
            tabIndex={0}
          >
            <ChevronLeftIcon className={classes.backIcon} color="inherit" />
            <Typography color="inherit" variant="button">
              Sector Breakdowns
            </Typography>
          </div>

          <List disablePadding>
            {sectors.map((sector) => (
              <ListItem
                key={sector.slug}
                className={classes.childListItem}
                button
                divider
                onClick={() =>
                  navigateTo(
                    href.landingPage,
                    asPath.landingPage('Sector breakdown', sector.slug),
                  )
                }
              >
                <ListItemText
                  primary={
                    <Typography variant="button">{sector.title}</Typography>
                  }
                />
              </ListItem>
            ))}
          </List>
        </div>
      </Slide>
    );
  }

  function renderSelectedMenu() {
    switch (selectedMenu) {
      case 'learn':
        return renderLearnMenu();

      case 'asx-market-data':
        return renderAsxMarketDataMenu();

      case 'raises':
        return renderRaisesMenu();

      case 'markets':
        return renderMarketsMenu();

      case 'sector-breakdowns':
        return renderSectorBreakdownsMenu();

      case 'use-cases':
        return renderUseCasesMenu();

      default:
        return renderRootLevelMenu();
    }
  }

  function renderUseCasesMenu() {
    return (
      <Slide direction="left" in>
        <div className={classes.container}>
          <div
            className={classes.backButton}
            onClick={() => setSelectedMenu('learn')}
            role="button"
            tabIndex={0}
          >
            <ChevronLeftIcon className={classes.backIcon} color="inherit" />
            <Typography color="inherit" variant="button">
              Use Cases
            </Typography>
          </div>

          <List disablePadding>
            {useCases.map((useCase) => (
              <ListItem
                key={useCase.slug}
                className={classes.childListItem}
                button
                divider
                onClick={() =>
                  navigateTo(
                    href.landingPage,
                    asPath.landingPage('Use case', useCase.slug),
                  )
                }
              >
                <ListItemText
                  primary={
                    <Typography variant="button">{useCase.title}</Typography>
                  }
                />
              </ListItem>
            ))}
          </List>
        </div>
      </Slide>
    );
  }

  function renderUserMenu() {
    if (data && data.me) {
      return (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <List className={classes.accountMenu} disablePadding>
              <ListItem button divider onClick={() => navigateTo(href.profile)}>
                <ListItemText
                  primary={
                    <Typography color="inherit" variant="button">
                      Account
                    </Typography>
                  }
                />
                {data.me.avatar ? (
                  <Avatar className={classes.avatar} src={data.me.avatar} />
                ) : (
                  <Avatar className={classes.avatar}>{getUserInitial()}</Avatar>
                )}
              </ListItem>
              <ListItem
                button
                divider
                onClick={() => navigateTo(routes.accountTransactions.href)}
              >
                <ListItemText
                  primary={
                    <Typography color="inherit" variant="button">
                      Transactions
                    </Typography>
                  }
                />
              </ListItem>
            </List>
          </Grid>
          <Grid item xs={12}>
            <Button
              color="primary"
              fullWidth
              endIcon={<ExitToAppIcon />}
              onClick={onClickLogOut}
              variant="outlined"
            >
              Log Out
            </Button>
          </Grid>
        </Grid>
      );
    }

    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Button
            color="secondary"
            fullWidth
            endIcon={<ExitToAppIcon />}
            onClick={() =>
              navigateTo({
                pathname: href.authLogIn,
                query: {
                  next: router.asPath,
                },
              })
            }
            variant="outlined"
          >
            Login
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Button
            color="secondary"
            fullWidth
            onClick={() =>
              navigateTo({
                pathname: href.authSignUp,
              })
            }
          >
            Become a client
          </Button>
        </Grid>
      </Grid>
    );
  }

  return (
    <Dialog
      fullScreen
      onClose={closeMenu}
      open={open}
      TransitionComponent={Transition}
    >
      <div className={classes.container}>
        <Grid container alignItems="center" justify="space-between" spacing={2}>
          {/* Logo */}
          <Grid item>
            <Link href={asPath.capitalRaises(!!(data && data.me))}>
              <a className={classes.flex}>
                {/* eslint-disable-next-line @next/next/no-img-element */}
                <img
                  className={classes.logo}
                  alt="Fresh Equities"
                  src="/images/assets/logo-horizontal-green.svg"
                />
              </a>
            </Link>
          </Grid>
          <Grid item>
            <Grid container alignItems="center" spacing={1} wrap="nowrap">
              <Grid item>
                <IconButton
                  edge="end"
                  onClick={() => setMobileSearchDialogOpen(true)}
                >
                  <SearchIcon />
                </IconButton>

                <MobileSearchDialog
                  closeMenu={closeMenu}
                  onClose={() => setMobileSearchDialogOpen(false)}
                  open={isMobileSearchDialogOpen}
                />
              </Grid>
              {!!(data && data.me) && (
                <Grid item>
                  <NotificationBellMobile
                    closeMenu={closeMenu}
                    logoHref={asPath.capitalRaises(!!(data && data.me))}
                  />
                </Grid>
              )}
              {/* Close Button */}
              <Grid item>
                <IconButton edge="end" onClick={closeMenu}>
                  <MenuIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>

      {renderSelectedMenu()}
    </Dialog>
  );
};

export default MobileMenu;
