import { gql } from '@apollo/client';

export interface AmILoginQueryData {
  me: {
    id: string;
  } | null;
}

export default gql`
  query MeQuery {
    me {
      id
    }
  }
`;
