import React, { FC, FocusEvent, useEffect, useRef, useState } from 'react';
// Modules
import { useLazyQuery } from '@apollo/client';
import clsx from 'clsx';
import debounce from 'lodash/debounce';
import Router from 'next/router';
// MUI Core
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import { makeStyles, Theme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
// MUI Icons
import CancelIcon from '@material-ui/icons/Cancel';
import SearchIcon from '@material-ui/icons/Search';
// MUI Lab
import Autocomplete from '@material-ui/lab/Autocomplete';
// GraphQL
import OrganisationListQuery, {
  OrganisationListItem,
  OrganisationListQueryData,
  OrganisationListQueryVariables,
} from '../../graphql-ts/queries/OrganisationListQuery.graphql';
// Utils
// import { EVENTS } from '../../utils-ts/analytics';
import { getOrganisationCodes } from '../../utils-ts/helper';
import { asPath, href } from '../../utils-ts/pages';

const useStyles = makeStyles((theme: Theme) => ({
  grow: {
    flex: '1 1 0',
    minWidth: 0,
  },
  logo: {
    backgroundColor: theme.palette.background.default,
    border: `solid 1px ${theme.palette.divider}`,
    borderRadius: 4,
    height: 48,
    minHeight: 48,
    minWidth: 48,
    overflow: 'hidden',
    width: 48,
  },
  logoContainer: {
    display: 'flex',
  },
  optionContainer: {
    overflow: 'hidden',
    width: '100%',
  },
  root: {
    backgroundColor: theme.palette.background.default,
    maxWidth: 300,
    borderRadius: 4,
    overflow: 'hidden',
  },
  searchBackdrop: {
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    opacity: 0,
    visibility: 'hidden',
    backgroundColor: 'rgba(90,98,112,0.5)',
    transitionProperty: 'opacity,visibility',
    transitionDuration: '150ms',
    transitionTimingFunction: 'ease-in-out',
    zIndex: 1,
  },
  searchBackdropOpen: {
    visibility: 'visible',
    opacity: 1,
  },
  wrapper: {
    position: 'relative',
    zIndex: 1,
  },
}));

interface SearchBarProps {
  autoFocus?: boolean;
  onBlur?: (event: FocusEvent<HTMLDivElement>) => void;
  size?: 'medium' | 'small';
}

const SearchBar: FC<SearchBarProps> = ({ autoFocus, onBlur, size }) => {
  const classes = useStyles();

  const [search, setSearch] = useState<string | null>(null);
  const [value, setValue] = useState('');
  const [cleared, setCleared] = useState(false);
  const debouncedSearch = useRef(debounce(runSearchQuery, 200));

  const [fetchSearchResults, { data, loading, networkStatus }] = useLazyQuery<
    OrganisationListQueryData,
    OrganisationListQueryVariables
  >(OrganisationListQuery, {
    fetchPolicy: 'cache-and-network',
    variables: {
      first: 4,
      options: {
        filters: [
          { key: 'invalidated', value: 'false' },
          { key: 'listed', value: 'true' },
          { key: 'search_full_text', value: `%${search}%` },
        ],
      },
    },
  });

  useEffect(() => {
    if (search && search !== '') {
      fetchSearchResults();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  const options =
    data && data.organisationList
      ? data.organisationList.edges.map(({ node }) => node)
      : [];

  function onSelect(e: any, newValue: OrganisationListItem | null) {
    const listing = newValue ? newValue.listings[0] : null;

    if (listing) {
      Router.push(
        href.organisation,
        asPath.organisation(listing.exchange.key, listing.key),
      );
    }
  }

  function onChange(event: React.ChangeEvent<HTMLInputElement>) {
    setValue(event.target.value);
    debouncedSearch.current(event.target.value);
  }

  function runSearchQuery(query: string) {
    setSearch(query);

    // window.analytics?.track(EVENTS.COMPANY_SEARCHED, {
    //   eventOrigin: 'SearchBar',
    //   value: query,
    // });
  }

  function onClose() {
    setCleared(!cleared);
    setSearch('');
    setValue('');
  }

  const searching = loading || [1, 2, 3, 4].includes(networkStatus);

  return (
    <>
      <div
        className={clsx(
          classes.searchBackdrop,
          value ? classes.searchBackdropOpen : '',
        )}
      />
      <Autocomplete
        blurOnSelect
        className={classes.wrapper}
        filterOptions={(opts) => opts}
        getOptionLabel={(option) => option.name}
        loading={searching}
        noOptionsText="Sorry, we can't find the company you are looking for."
        onBlur={onBlur}
        onChange={onSelect}
        onClose={onClose}
        open={!!search}
        key={`search-input-${cleared}`}
        options={options}
        renderInput={(params) => (
          <TextField
            {...params}
            autoFocus={autoFocus}
            className={classes.root}
            fullWidth
            InputProps={{
              ...params.InputProps,
              className: undefined,
              endAdornment: !!search && (
                <InputAdornment position="end">
                  <IconButton edge="end" onClick={onClose} size="small">
                    <CancelIcon fontSize="inherit" />
                  </IconButton>
                </InputAdornment>
              ),
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon color="action" />
                </InputAdornment>
              ),
            }}
            onChange={onChange}
            placeholder="Company / ASX Code"
            variant="outlined"
            type="text"
          />
        )}
        renderOption={(option) => (
          <div className={classes.optionContainer}>
            <Grid container alignItems="center" spacing={2} wrap="nowrap">
              <Grid item className={classes.logoContainer}>
                {/* eslint-disable-next-line @next/next/no-img-element */}
                <img
                  className={classes.logo}
                  alt={option.name}
                  src={
                    option.logoThumbUrl || '/images/assets/logo-placeholder.svg'
                  }
                />
              </Grid>
              <Grid item className={classes.grow}>
                <Typography noWrap>{getOrganisationCodes(option)}</Typography>
                <Typography color="textSecondary" noWrap variant="body2">
                  {option.name}
                </Typography>
              </Grid>
            </Grid>
          </div>
        )}
        size={size}
      />
    </>
  );
};

export default SearchBar;
