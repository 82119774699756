import React, { FC } from 'react';
// Modules
import { useRouter } from 'next/router';
// Components
import MegaMenu from './MegaMenu';
// Utils
import { href } from '../../../../utils-ts/pages';

const ContactUs: FC = () => {
  const router = useRouter();

  function isSelected() {
    return [href.contactUs].includes(router.pathname);
  }

  return (
    <MegaMenu selected={isSelected()} href={href.contactUs} text="Contact Us" />
  );
};

export default ContactUs;
