// Modules
import { useRouter } from 'next/router';

const useIsRetailOnboardingPathway = () => {
  const {
    query: { retail },
  } = useRouter();
  return retail === 'true';
};

export default useIsRetailOnboardingPathway;
