import React from 'react';
// FUI
import Typography from '../../../fui/v2/Typography';

const InvestorHubNavBar: React.FC = () => {
  return (
    <div className="z-[1100] p-4 bg-premium">
      <Typography className="text-center" variant="body-bold-lg">
        <a
          href="https://www.investorhub.com/"
          target="_blank"
          className="text-grey no-underline"
          rel="noopener noreferrer"
        >
          As of August 2023, Fresh Equities only supports capital raises run by
          InvestorHub clients.
        </a>
      </Typography>
    </div>
  );
};

export default InvestorHubNavBar;
